import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Card, Spinner } from 'react-bootstrap';

// import components
import TopBoxes from './TopBoxes';
import PendingPayments from './PendingPayments';
import PartialPaid from './PartialPaid';
import EndingDays from './EndingDays';

// import images
import photo1 from '../../../images/shape1.svg';
import photo2 from '../../../images/shape2.svg';
import photo3 from '../../../images/shape3.svg';
import photo4 from '../../../images/shape4.jpg';
import arrowicon from '../../../images/arrowicon.svg';
import photoavaatr from '../../../images/avatarpic3.jpg';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import MySectorBox from '../../PublicPages/componnents/MySectorBox';
import { useAuth } from '../auth/AuthProvider';
import toast from 'react-hot-toast';
import { pusher } from '../../../pusherConfig';


const Dashboard = () => {
  const [dashData, setDashData] = useState('')
  const { token, updateToken } = useAuth();
  const [metaData, setMetaData] = useState({
    title: '',
    description: '',
    previewImage: '',
  });
  const Navigate = useNavigate()


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    axios.get(`${API_BASE_URL}/${token.role === 'admin' ? 'dashboardsummary' : 'user-dashboard'}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data

      setDashData(response?.data?.data)
    }).then(result => {
      // Handle the response data
      // console.log(result);
    })
      .catch(error => {
        // Handle errors
        // console.log('error', error);
        // Unauthenticated.
        if (error.response.data.message == 'Unauthenticated.') {
          localStorage.removeItem('token');
          Navigate('/signin')
        }
      });
  }, [])

  useEffect(() => {
    dashData && setMetaData({
      title: dashData?.sector_summary[0]?.name,
      description: dashData?.sector_summary[0]?.name,
      previewImage: dashData?.sector_summary[0]?.image,
    });
  }, [dashData]);
  // console.log(metaData);

  const getRandomColor = () => {
    const randomNum = Math.floor(Math.random() * 3); // Generates a random number between 0 and 2
    switch (randomNum) {
      case 0:
        return 'primarylighterbg';
      case 1:
        return 'infolighterbg';
      case 2:
        return 'warninglighterbg';
      default:
        return 'errorlighterbg';
    }
  };


  // console.log(token);
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta property="og:image" content={metaData.previewImage} />
      </Helmet>
      {!dashData && (
        <Row className={'my-4'}>
          <div className={'mt-3 w-100 text-center'}>
            <Skeleton count={1} width={'100%'} height={250} />
          </div>
        </Row>
      )}
      {token.role === 'admin' && dashData ?
        <>
          <div className={'main_heading'}><h4>All Time</h4></div>
          <Row className={'my-4'}>
            <>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_bookings}</h4>
                  <small className={'fontweight500'}>Total Bookings</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_confirmed}</h4>
                  <small className={'fontweight500'}>Total Confirmed</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_canceled}</h4>
                  <small className={'fontweight500'}>Total Canceled</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_paid}</h4>
                  <small className={'fontweight500'}>Total Paid</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_partial_paid}</h4>
                  <small className={'fontweight500'}>Total Partial Paid</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.overall_summary?.total_unpaid}</h4>
                  <small className={'fontweight500'}>Total Unpaid</small>
                </Card>
              </Col>
            </>
          </Row>
          <div className={'main_heading'}><h4>Today Summary</h4></div>
          <Row className={'my-4'}>
            <>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.today_count}</h4>
                  <small className={'fontweight500'}>Total Bookings</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.confirmed}</h4>
                  <small className={'fontweight500'}>Total Confirmed</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.canceled_count}</h4>
                  <small className={'fontweight500'}>Total Canceled</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.paid_count}</h4>
                  <small className={'fontweight500'}>Total Paid</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.partial_paid_count}</h4>
                  <small className={'fontweight500'}>Total Partial Paid</small>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
                <Card className={'card-body' + getRandomColor()} style={{ maxHeight: '100px' }}>
                  <h4 className={'title text-decoration-none'}>{dashData.today_summary?.unpaid_count}</h4>
                  <small className={'fontweight500'}>Total Unpaid</small>
                </Card>
              </Col>
            </>
          </Row>
        </> : null}
      <div className={'main_heading'}><h4>All Sectors</h4></div>
      <Row className={'mt-4 topBoxes'}>
        {dashData && dashData?.sector_summary?.map((val, i) => {
          return (
            <>
              {token.role === 'admin' ?
                <TopBoxes
                  key={i}
                  boxcolor={getRandomColor()}
                  colordiffrent={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  colormini={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  bgcolor={i == 0 ? 'gradiant1bg' : i == 1 ? 'gradiant1bg' : i == 2 ? 'gradiant2bg' : 'gradiant3bg'}
                  title={val?.name}
                  minitext={val?.totalpnr}
                  photoicon={val?.image}
                  id={val?.id}
                />
                :
                <MySectorBox
                  key={i}
                  boxcolor={getRandomColor()}
                  colordiffrent={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  colormini={i == 0 ? 'solidcolor1text' : i == 1 ? 'solidcolor2text' : i == 2 ? 'solidcolor3text' : 'solidcolor4text'}
                  bgcolor={i == 0 ? 'gradiant1bg' : i == 1 ? 'gradiant1bg' : i == 2 ? 'gradiant2bg' : 'gradiant3bg'}
                  title={val?.name}
                  minitext={val?.totalpnr}
                  photoicon={val?.image}
                  id={val?.id}
                />
              }
            </>
          )
        })}
      </Row>
      {/*  */}
      {token.role === 'admin' &&
        <>
          <section className={'pt-lg-5 pt-4 pb-lg-4 pb-3 listContent'}>
            <Row className={'mt-lg-4 mt-3'}>
              <Col className={'mb-4 mb-lg-0'} xxl={'7'} xl={'7'} lg={'7'}>
                <Card className={''}>
                  <Card.Header className={'px-3 px-lg-4 py-2'}><h4>Pending Payments</h4></Card.Header>
                  <Card.Body className={'py-3 px-3 px-lg-4'}>
                    {!dashData && (
                      <div className={'mt-3 w-100 text-center'}>
                        {/* <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> */}
                        <Skeleton count={4} width={'100%'} height={40} />
                      </div>
                    )}
                    {dashData && dashData?.pending?.map((val) => {
                      return (
                        <PartialPaid
                          key={val.key}
                          avatarpic={val.airline_logo}
                          title={val.agent_name || val?.name}
                          content={val.pnr}
                          date={val.booking_date}
                          id={val.id}
                          confirm={val.confirmed}
                        />
                      )
                    })}
                    {dashData?.pending?.length < 1 && <p>No Data Found</p>}
                  </Card.Body>
                  <Card.Footer className={'px-3 px-lg-4 py-3 text-end'}>
                    <Link to={'/dashboard/pendingbookings'} className={'fontsize14 text-decoration-none fontweight600 blacktext'}>View All <img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Link>
                  </Card.Footer>
                </Card>
              </Col>
              {/*  */}
              <Col className={''} xxl={'5'} xl={'5'} lg={'5'}>
                <Card className={''}>
                  <Card.Header className={'px-3 px-lg-4 py-2'}><h4>Partial Paid</h4></Card.Header>
                  <Card.Body className={'py-3 px-3 px-lg-4'}>
                    {!dashData && (
                      <div className={'mt-3 w-100 text-center'}>
                        {/* <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> */}
                        <Skeleton count={4} width={'100%'} height={40} />
                      </div>
                    )}
                    {dashData && dashData?.partials?.map((val) => {
                      return (
                        <PartialPaid
                          key={val.key}
                          avatarpic={val.airline_logo}
                          title={val.agent_name || val?.name}
                          content={val.pnr}
                          date={val.booking_date}
                          id={val.id}
                          confirm={val.confirmed}
                        />
                      )
                    })}
                    {dashData?.partials?.length < 1 && <p>No Data Found</p>}
                  </Card.Body>
                  <Card.Footer className={'px-3 px-lg-4 py-3 text-end'}>
                    <Link to={'/payments'} className={'fontsize14 text-decoration-none fontweight600 blacktext'}>View All <img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Link>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </section>
          {/*  */}
          <section className={'listContent'}>
            <Card className={''}>
              <Card.Header className={'px-3 px-lg-4 py-2'}><h4>Ending Soon</h4></Card.Header>
              <Card.Body className={'py-3 px-3 px-lg-4'}>
                {!dashData && (
                  <div className={'mt-3 w-100 text-center'}>
                    {/* <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> */}
                    <Skeleton count={4} width={'100%'} height={40} />
                  </div>
                )}
                {dashData && dashData?.pnrs?.map((val, i) => {
                  return (
                    <EndingDays
                      key={val.key}
                      pnr={val.pnr}
                      pnr_id={val.pnr_id}
                      date={val.departure_date}
                      sold={val.sold}
                      rem={val.remaining}
                      total={val.total_ticket}
                      d_sector={val.departure_sector_name}
                    />
                  )
                })}
              </Card.Body>
            </Card>
          </section>
        </>}
    </>
  )
}

export default Dashboard;