import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Card, Col, Dropdown, Form, NavItem, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';



// import component
import arrowicon from '../../../images/arrowicon.svg';
import verticalicon from '../../../images/verticalicon.svg';
import { API_BASE_URL, getBearerToken, getLoadingState, setLoadingState } from '../../../functions';
import { useAuth } from '../auth/AuthProvider';
import AccountsTRLoop from '../User/Accounts/AccountsTRLoop';
import AgentBlncTRLoop from '../User/Accounts/AgentBlncTRLoop';
import arrowlefticon from '../../../images/arrowlefticon.svg';


const AgentsBalance = () => {
  const [payments, setPayments] = useState('')
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key
  const [loading, setLoading] = useState(false);
  const { token } = useAuth()
  const [reportQuery, setReportQuery] = useState(
    {
      from: '',
      to: '',
      type: null,
      selected_type: 'date',
      agent_id: null,
    }
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiData, setApiData] = useState('')




  useEffect(() => {
    setLoadingState(true, 'PAYMENTS_LIST');
    setLoading(true)
    axios.get(`${API_BASE_URL}/allagentsbalancelist?page=${currentPage}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setLoadingState(false, '');
      if (currentPage === 1) {
        setPayments(response?.data?.data?.data)
      } else {
        setPayments(preData => [...preData, ...response?.data?.data?.data]);
      }
      setLoading(false)
      setApiData(response?.data?.data);
    }).catch(error => {
      // Handle errors
      console.error(error);
      setLoadingState(false, '');
      setLoading(false)

    });
  }, [currentPage])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    if (reportQuery.selected_type === 'date') {
      setReportQuery({ ...reportQuery, type: null })
    }
  }, [reportQuery.selected_type])

  const handelSelect = (e) => {
    setReportQuery({ ...reportQuery, from: '', to: '', type: e, selected_type: e })
  }
  const nextPage = () => {
    if (apiData.next_page_url) {
      setCurrentPage(currentPage + 1);
      setLoading(true)
    }
  };

  const prevPage = () => {
    if (apiData.prev_page_url) {
      setCurrentPage(currentPage - 1);
      setPayments(preData => preData.slice(0, -apiData.per_page));
      setLoading(true)
    }
  };

  // console.log('payments', payments);

  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        <h4>Agents Balances</h4>
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      {loading && payments.length < 1 ? (
        // Show loading spinner or skeleton loader while data is being fetched
        <div className={'mt-3 w-100 text-center'}>
          <Spinner as='span' animation='border' size='lg' role='status' aria-hidden='true' />
        </div>
      ) : (
        <Row className={'m-0 p-0'}>
          <Col className={'mb-4 mb-lg-0'} xxl={'12'} xl={'12'} lg={'12'}>
            <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
              <Card.Header className="px-3 px-lg-4 py-3" style={{ background: 'transparent', border: 'none' }}>
                <h5 className="fontsize18 blacktext fontweight600">Balance Report</h5>
                {/* <Form className='AllForm'>
                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                  <Form.Label className='m-0'>Agent Name</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      placeholder="Search Agent by name"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) => handleSearch(e)}
                      value={searchTerm}
                      required
                    />
                  </div>
                </Form.Group>
              </Form> */}
              </Card.Header>
              {payments && <>
                <Card.Body className="p-0">
                  <AgentBlncTRLoop
                    data={payments}
                    toastMessage={toastMessage}
                    setToastMessage={setToastMessage}
                    isLoading={isLoading}
                    loadingKey={loadingKey}
                    showToast={showToast}
                    setShowToast={setShowToast} />
                  {!payments || loading && (
                    <div className={'mt-3 w-100 text-center'}>
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                    </div>
                  )}
                </Card.Body>
                <Card.Footer className='px-3 px-lg-4 py-3 text-end border-0' style={{ background: 'transparent', }}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Badge bg={''} className={'p-2 py-1 gradiant2bg greentext'}>{payments && payments?.length} <span className='blacktext'>of</span> {apiData?.total}</Badge>
                    <p className='p-0 m-0 fontsize12 blacktext fontweight500 mx-3'>Per Page : {apiData?.per_page} </p>
                    <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} disabled={!apiData.prev_page_url || true}><img className={'img-fluid'} src={arrowlefticon} alt={'icon'} /></Button>
                    <span>{currentPage}</span>
                    <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={nextPage} disabled={!apiData.next_page_url}><img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Button>
                  </div>
                </Card.Footer>
              </>}
            </Card>
          </Col>
        </Row >
      )}
    </>
  )
}

export default AgentsBalance;