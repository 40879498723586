import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, countries, getBearerToken } from '../../functions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import { useAuth } from '../pages/auth/AuthProvider';

const UpdateProfile = ({ show, setShow, path = '', editData, agent_id }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { token } = useAuth()

    // const Navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        agency_name: '',
        email: '',
        phone: '',
        image: null,
        city: '',
        country: '',
        office_address: '',
        office_no: '',
        propriter_name: '',
    });

    // Add a state to track whether the modal is in edit mode or not
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        if (path === 'admin') {
            setLoading(true)
            axios.get(`${API_BASE_URL}/editagent/${agent_id}`, {
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            })
                .then((response) => {
                    setData({ ...response.data.data });
                    setLoading(false);
                })
                .then((result) => {
                    setLoading(false);
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(false);
                });
        }
    }, []);

    // console.log(editData);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('agency_name', data.agency_name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('image', data.image);
        formData.append('city', data.city);
        formData.append('country', data.country);
        formData.append('office_address', data.office_address);
        formData.append('office_no', data.office_no);
        formData.append('propriter_name', data.propriter_name);

        if (formData) {
            // Handle edit operation
            axios.post(`${API_BASE_URL}/updateagent/${agent_id}`, formData, {
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            })
                .then((response) => response)
                .then((result) => {
                    setToastMessage({
                        type: 'success',
                        message: 'Agent updated successfully!',
                        show: 'show',
                    });
                    setLoading(!loading);
                    handleClose();
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(!loading);
                });
        }
    };

    // console.log("token.agent_id", token.agent_id);

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="d-flex px-4 align-items-center">
                    <Modal.Title id="contained-modal-title-vcenter">Edit Profile</Modal.Title>
                </Modal.Header>
                {path === 'agent' ?
                    <Modal.Body className="px-4">
                        <p>You can not chnage your profile directly. you need to contact admin to change your profile. You can contact with admin by the below details</p>
                        <a className='' href='tel:+92304817601'>Call</a><br /><br />
                        <a href='https://wa.link/89syfn' target='_blank'>Whatsapp</a>
                    </Modal.Body>
                    : <Form className={'AllForm'} onSubmit={handleSubmit}>

                        {loading ?
                            <div className="text-center p-4">
                                <Spinner as='span' animation='border' size='lg' role='status' aria-hidden='true' />
                            </div>
                            :
                            <>
                                <Modal.Body className="px-4">
                                    {/*  */}
                                    <Row className={'my-4'}>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Propriter Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Name'}
                                                    value={data.name} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, name: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Agency Name</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Name'}
                                                    value={data.agency_name} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, agency_name: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'12'} lg={'12'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="email"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Email'}
                                                    value={data.email} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Personal Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Contact Number'}
                                                    value={data.phone} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, phone: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Office Number</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Contact Number'}
                                                    value={data.office_no} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, office_no: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'12'} lg={'12'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Office Address</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name={''}
                                                    id={''}
                                                    placeholder={'Office Address'}
                                                    value={data.office_address} // Set the value prop to the corresponding data property
                                                    onChange={(e) => setData({ ...data, office_address: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="0">City</Form.Label>
                                                <Form.Control
                                                    placeholder="City"
                                                    type="address"
                                                    id="Address"
                                                    name="Address"
                                                    required
                                                    value={data?.city}
                                                    onChange={(e) => setData({ ...data, city: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label className="0">Country</Form.Label>
                                                <Form.Select
                                                    value={data.country}
                                                    onChange={(e) => setData({ ...data, country: e.target.value })}>
                                                    <option value="">Select</option>
                                                    {countries.map((country, index) => (
                                                        <option key={index} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className={'mb-4'} xl={'12'} lg={'12'}>
                                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Image/Logo</Form.Label>
                                                {!(data?.image) ?
                                                    <Form.Control
                                                        // required={!editData?.image} // Mark as required only when adding a new agent
                                                        type="file"
                                                        name={''}
                                                        id={''}
                                                        placeholder={'Agent image/logo'}
                                                        onChange={(e) => setData({ ...data, image: e.target.files[0] })}
                                                    />
                                                    :
                                                    <div className="d-flex align-items-center">
                                                        <img src={data.image} alt="Agent" width="100" height="100" className="me-2" />
                                                        <Button variant="outline-danger" size="sm" onClick={() => setData({ ...data, image: null })}>
                                                            Remove Image
                                                        </Button>
                                                        {/* // Check if data.image is falsy (no image is present) */}

                                                    </div>
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                {/*  */}
                                <Modal.Footer className="">
                                    <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                                    <Button variant="" disabled={loading} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                                        {loading ?
                                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                            : editMode ? 'Update' : 'Save'
                                        }
                                    </Button>
                                </Modal.Footer>
                            </>}
                    </Form>}
            </Modal>
        </>
    )
}

export default UpdateProfile;