import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import arrowicon from "../../images/arrowicon.svg";


function Dropdown({ icon, text, link, isactivecheck, items, isActive }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };
    // console.log('isActive(isactivecheck)', items, isactivecheck, isActive(isactivecheck));
    return (
        <div className="mb-2 position-relative">
            <Nav.Item className="mb-2" onClick={toggleDropdown}>
                <Link to={link} className={`nav-link justify-content-between ${isActive(isactivecheck) ? 'activenav' : ''} `}>
                    <div className="d-flex alignitems-start">
                        <p className="mb-0 space"><img className="img-fluid" src={icon} alt="icon" /></p><div className="hidetext ms-2">{text}</div>
                    </div>
                    {items?.length > 0 &&
                        <div>
                            <img className={`img-fluid droparrorw ${isOpen && 'rotate90'}`} src={arrowicon} alt="icon" />
                        </div>
                    }
                </Link>
            </Nav.Item>
            <div className="sub-menu ps-3">
                {isOpen && (
                    <>
                        {items.map((item, index) => (
                            <Nav.Item key={index} className="mb-2">
                                <Link
                                    to={item.link}
                                    className={`nav-link ${isActive(item.isactivecheck) ? 'activenav' : ''}`}
                                // onClick={closeDropdown}
                                >
                                    {item.icon ? <p className="mb-0 space">
                                        <img className="img-fluid" src={item.icon} alt="icon" />
                                    </p>
                                        :
                                        <span className={isActive(item.isactivecheck) ? 'listdot-active' : 'listdot'}></span>}
                                    <div className="hidetext ms-2">{item.text}</div>
                                </Link>
                            </Nav.Item>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}

export default Dropdown;
