import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import planeicon from '../../../images/planeicon.svg'
import BookTicket from '../../Modals/BookTicket';
import SigninPopup from '../../Modals/SigninPopup';
import { formatDate } from '../../../functions';


const TicketCard = ({ data }) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [selectedPnr, setSelectedPnr] = useState({ date: '', pnr: '', price: 0 });
    const [isLogin, setIsLogin] = useState(false);
    const [showSelectedSubparts, setShowSelectedSubparts] = useState(false);
    const [selectedSubpartIndex, setSelectedSubpartIndex] = useState(null);

    useEffect(() => {
        let checkLogin = localStorage.getItem('token');
        if (checkLogin) {
            setIsLogin(true);
        }
    }, []);

    const handleShow = () => {
        let checkLogin = localStorage.getItem('token');
        if (checkLogin) {
            setIsLogin(true);
            setModalShow(true);
        } else {
            setIsLogin(false);
            setModalShow(true);
        }
    };

    const handleLoginSuccess = () => {
        // After a successful login, set isLogin to true and open the BookTicket popup.
        setIsLogin(true);
        setModalShow(true);
    };

    const ShowSubparts = (index) => {
        if (isLogin) {
            setShowSelectedSubparts((prev) => (index === selectedSubpartIndex ? !prev : true));
            setSelectedSubpartIndex(index);
        } else {
            handleShow()
        }
    }

    // console.log('TicketCard', data);
    return (
        <>
            {data?.length > 0 ? data?.map((val, i) => {
                return (
                    <>
                        <tr className={`bookingbox cursor-pointer ${(showSelectedSubparts && selectedSubpartIndex === i) && 'selected-tr'}`} onClick={() => ShowSubparts(i)}>
                            <td className="">
                                <img alt='icon' className='' title={val?.air_line} src={val?.air_line_logo} style={{ width: "80px", height: "40px", objectFit: 'contain' }} />
                            </td>
                            <td className='tsector'>
                                <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.sector}</h5>
                                {val.subpart?.map((inval, i) => (
                                    <p className='fontsize14 fontweight600 p-0 m-0 '>{inval?.day}, {formatDate(inval?.departure_date)}</p>
                                ))
                                }
                            </td>
                            <td className='text-center'>
                                <img alt='icon' className='' src={planeicon} />
                                {val.subpart?.map((inval, i) => (
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_time} - {inval?.arivval_time}</p>
                                ))}
                            </td>
                            <td className='text-end'>
                                <h5 className='fontweight600 fontsize14 mainblue'>{`${val.meal == 1 ? 'Meal' : 'Meal Not'} Available`}</h5>
                                {val.ticket_type == 2 ?
                                    (val.subpart?.map((inval, i) => (
                                        (i == 0 || i == 1) && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                    ))
                                    )
                                    :
                                    (val.subpart?.map((inval, i) => (
                                        i == 0 && <p className='fontsize14 fontweight600 p-0 m-0'>{inval?.departure_baggage} + 7kg</p>
                                    ))
                                    )
                                }
                            </td>
                            <td className='text-end'>
                                {isLogin ?
                                    <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.price > 0 ? `PKR - ${val?.price}` : 'Fare on call'}</h5>
                                    :
                                    <Button variant={''} className={'basic-btn mainbluebg'} style={{ height: '40px' }}
                                        onClick={(e) => {
                                            handleShow()
                                        }}>Login For Price</Button>
                                }
                                {val.alert && 
                                    <span className='fontsize12 fontweight500 mt-1 m-0 alert'>{val.alert}</span>
                                }
                            </td>
                            <td align={'end'}>
                                <Button variant={''} className={'basic-btn text-uppercase'} style={{ height: '40px' }}
                                    onClick={(e) => {
                                        setSelectedPnr({ date: val?.date, pnr: val?.pnr , price: val?.price });
                                        handleShow()
                                    }}>Book</Button>
                            </td>
                        </tr>
                        {/* {(showSelectedSubparts && selectedSubpartIndex === i) &&
                            <> */}
                        {val?.subpart?.map((invall, ii) => (
                            <tr className={`subpart-row-entering ${(showSelectedSubparts && selectedSubpartIndex === i) && 'subpart-row-entered'}`} id={i} style={{ background: 'black', color: 'white' }}>
                                <td>
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{val.air_line}</p>
                                </td>
                                <td>
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_sector_name} , {formatDate(invall?.departure_date)}</p>
                                </td>
                                <td className='text-center'>
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_time} - {invall?.arivval_time}</p>
                                </td>
                                <td className='text-end'>
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{invall?.departure_baggage || 'N/A'}</p>
                                </td>
                                <td className='text-end'>
                                    <p className='fontsize14 fontweight600 p-0 m-0'>{val?.price}</p>
                                </td>
                                <td></td>
                            </tr>
                        ))}
                        {/* </>
                        } */}
                    </>
                    // <div className='mt-4'>
                    //     <div className='ticketbox flex-column flex-md-row'>
                    //         <div className="airline">
                    //             <img alt='icon' className='' title={val?.airline_name} src={val?.airline_logo} style={{ width: "80px", height: "40px", objectFit: 'contain' }} />
                    //             {/* <p className='p-0 m-0 fontsize16 fontweight400'>{val?.airline_name}</p> */}
                    //         </div>
                    //         <div className="airdetails flex-column flex-md-row">
                    //             <div className='tsector'>
                    //                 <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.sector}</h5>
                    //                 <p className='fontsize16 p-0 m-0 '>{val?.day}, {formatDate(val?.date)}</p>
                    //             </div>
                    //             <div className='text-center'>
                    //                 <img alt='icon' className='' src={planeicon} />
                    //                 <p className='fontsize16 p-0 m-0'>{val?.departure_time} - {val?.arivval_time}</p>
                    //             </div>
                    //             <div className='text-end'>
                    //                 <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.baggage || 'N/A'}</h5>
                    //                 <p className='fontsize16 p-0 m-0 '>{'Meal'}</p>
                    //             </div>
                    //             <div className='text-end'>
                    //                 <h5 className='text-uppercase fontweight700 fontsize20 mainblue'>{val?.price ? `PKR - ${val?.price}` : 'Fare on call'}</h5>
                    //                 {/* <p className='fontsize16 p-0 m-0 '>{val?.price ? `PKR - ${val?.price}` : 'Fare on call'}</p> */}
                    //             </div>
                    //         </div>
                    //         <div className="bookbtn">
                    //             <Button variant={''} className={'basic-btn text-uppercase'} style={{ height: '56px' }}
                    //                 onClick={(e) => {
                    //                     setSelectedPnr({ date: val?.date, pnr: val?.pnr });
                    //                     handleShow()
                    //                 }}>Book</Button>
                    //         </div>
                    //     </div>
                    // </div>
                )
            })
                :
                <h4 className='redtext m-0 p-0'>No Ticket Found</h4>
            }
            {(modalShow && isLogin) ? (
                <BookTicket show={modalShow} setShow={setModalShow} selectedPnr={selectedPnr} bookType={'user'} />
            ) : (
                <SigninPopup show={modalShow} setShow={setModalShow} onLoginSuccess={handleLoginSuccess} />
            )}
        </>

    )
}

export default TicketCard
