import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken } from '../../functions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';

const AddSector = ({ show, setShow, editData }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const Navigate = useNavigate()



    const [data, setData] = useState({
        sector_name: '',
        from_sector: '',
        to_sector: '',
        from_city: '',
        to_city: '',
    });

    useEffect(() => {
        setData({ ...data, sector_name: `${data.from_sector} - ${data.to_sector}` })
    }, [data.from_sector, data.to_sector])

    useEffect(() => {
        // If editData is provided, update the data state with the editData
        if (editData) {
            setData({ ...editData });
            setEditMode(true);
        } else {
            // If editData is not provided, reset the data state for adding a new sector
            setData({
                sector_name: '',
                from_sector: '',
                to_sector: '',
                from_city: '',
                to_city: '',
            });
            setEditMode(false);
        }
    }, [editData]);

    // console.log(editData, data);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('from_sector', data.from_sector);
        formData.append('to_sector', data.to_sector);
        formData.append('from_city', data.from_city);
        formData.append('to_city', data.to_city);

        if (editMode) {
            // Update an existing sector
            formData.append('sector_name', data.sector_name);
            // Make your update API call here
            axios
                .post(`${API_BASE_URL}/updatesector/${editData.id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${getBearerToken()}`
                    }
                })
                .then((response) => {
                    setToastMessage({ type: 'success', message: 'Sector updated successfully!', show: 'show' });
                    window.location.reload();
                    setLoading(false);
                    handleClose();
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(false);
                });
        } else {
            // Add a new sector
            formData.append('sector_name', `${data.from_sector} - ${data.to_sector}`);
            // Make your add API call here
            axios
                .post(`${API_BASE_URL}/add-sector`, formData, {
                    headers: {
                        'Authorization': `Bearer ${getBearerToken()}`
                    }
                })
                .then((response) => {
                    setToastMessage({ type: 'success', message: 'Sector added successfully!', show: 'show' });
                    setLoading(false);
                    handleClose();
                    window.location.reload();
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(false);
                });
        }
    };

    // console.log(data);

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit}>
                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">
                            {editMode ? 'Edit Sector' : 'Add Sector'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        <Row className={'my-4'}>
                            <Col className={''} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>From Sector</Form.Label>
                                    <Form.Control
                                        className='text-uppercase'
                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'Short Name'}
                                        value={data.from_sector} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, from_sector: e.target.value.toUpperCase() })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={''} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>From Sector City Name</Form.Label>
                                    <Form.Control
                                        className='text-uppercase'
                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'City Name'}
                                        value={data.from_city} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, from_city: e.target.value.toUpperCase() })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={'my-4'}>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>To Sector</Form.Label>
                                    <Form.Control
                                        className='text-uppercase'
                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'Short Name'}
                                        value={data.to_sector} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, to_sector: e.target.value.toUpperCase() })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>To Sector City Name</Form.Label>
                                    <Form.Control
                                        className='text-uppercase'
                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'City Name'}
                                        value={data.to_city} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, to_city: e.target.value.toUpperCase() })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4 mb-lg-0'} xl={'12'} lg={'12'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Sector Full Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'Full Name'}
                                        value={data?.sector_name}
                                        readOnly // Make the input readOnly to show the value without being editable
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    {/*  */}
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : editMode ? 'Update' : 'Save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddSector;