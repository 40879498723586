import React, { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, NavItem, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, calculateDuration, getBearerToken } from '../../functions';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import AddAgent from './AddAgent';
import { useAuth } from '../pages/auth/AuthProvider';
import toast from 'react-hot-toast';

const TimeUpdate = ({ show, setShow, selectedBooking }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addAgent, setAddAgent] = useState(false);
    const [error, setError] = useState('');
    const [booked, setBooked] = useState(false);
    const [customer, setCustomer] = useState('');
    const Navigate = useNavigate()
    const [searchResults, setSearchResults] = useState([]);
    const [type, setType] = useState('')
    const [bookingType, setBookingType] = useState({
        adult: 'adult',
        Acount: 0,
        child: 'child',
        Ccount: 0,
        infant: 'infant',
        Icount: 0,
    });
    const [passengerDetails, setPassengerDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selected, setSelected] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [data, setData] = useState({
        booking_id: selectedBooking?.id,
        cancel_at: selectedBooking.cancel_at,
    });

    useEffect(() => {
        setSearchTerm('');
        setSelected(false);
        // customer == 'Walking' && setData({ ...data, agent: 0, walking: 'walking' })
        // customer == 'Agent' && setData({ ...data, agent: 0, walking: '' })
    }, [customer])



    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const fromdata = new FormData();
        fromdata.append('booking_id', data.booking_id)
        fromdata.append('cancel_at', data.cancel_at)
        // Call API to submit data
        axios.post(`${API_BASE_URL}/hold-time-update`, fromdata, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                toast.success(response.data.message);
                handleClose()
            })
            .then(result => {
                // Handle response from API
                toast.success(result.data.message);
                setLoading(false)
                handleClose()
            })
            .catch(error => {
                setLoading(false)
                toast(response.data.message);
            });
    };

    // console.log('data', data, selectedBooking);
    // console.log('=====>', data?.booking_sub?.length && data?.booking_sub[0]['firstname']);
    // console.log('selectedPnr', selectedPnr, id);

    return (
        <>
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit} id="myForm">
                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">Update Booking Hold Time</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        <Col className={'mb-4'} xl={'4'} lg={'4'}>
                            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                <Form.Label>Booking Hold Time</Form.Label>
                                <Form.Control type="datetime-local" name={''} id={''} placeholder={'Date of Birth'} required max={new Date().toISOString().split("T")[0]}
                                    // onChange={(e) => setData({ ...data, dob: e.target.value })} 
                                    onChange={(e) => { setData({ ...data, cancel_at: e.target.value }) }}
                                    value={data?.cancel_at}
                                />
                            </Form.Group>
                        </Col>
                        <p className='redtext mt-2'>{error}</p>
                    </Modal.Body>
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading || error} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : 'Save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {addAgent &&
                <AddAgent show={addAgent} setShow={setAddAgent} path='booking' />
            }
        </>
    )
}

export default TimeUpdate;