import React from 'react'
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const EmailPage = () => {

    const handelshow = (e) => {
        e.preventDefault()
    }
    return (
        <>
            <div className={'main_heading mb-4 d-flex align-items-center'}>
                <h4>Emails:</h4>
                <Link to={'#'} className='ms-auto btn greenbg text-white fontsize14 fontweight700' style={{ minWidth: '160px', maxWidth: '160px' }} onClick={handelshow}>Send Email</Link>
            </div>
            {/*  */}
            <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
                <Card.Header className='px-3 px-lg-4 py-3' style={{ background: 'transparent', border: 'none', }}><h5 className={'fontsize18 blacktext fontweight600'}>Emails</h5></Card.Header>
                <Card.Body className={'p-0 mb-3'}>
                    <Table responsive={'sm'} className={'mb-0 allView'}>
                        <thead className={'thead-dark'}>
                            <tr>
                                <th>Sr#</th>
                                <th>To</th>
                                <th>Subject</th>
                                <th>Status</th>
                                <th></th>
                                <th></th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                            </>
                        </tbody>
                    </Table>
                </Card.Body>
                <Card.Footer className='px-3 px-lg-4 py-3 text-end' style={{ background: 'transparent', }}>
                    <div className="d-flex align-items-center justify-content-end">
                        {/* <Badge bg={''} className={'p-2 py-1 gradiant2bg greentext'}>{airlines && airlines?.length} <span className='blacktext'>of</span> {apiData?.total}</Badge> */}
                        {/* <p className='p-0 m-0 fontsize12 blacktext fontweight500 mx-3'>Per Page : {apiData?.per_page} </p> */}
                        {/* <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={prevPage} disabled={!apiData.prev_page_url}><img className={'img-fluid'} src={arrowlefticon} alt={'icon'} /></Button> */}
                        {/* <span>{currentPage}</span> */}
                        {/* <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={nextPage} disabled={!apiData.next_page_url}><img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Button> */}
                    </div>
                </Card.Footer>
            </Card>
        </>
    )
}
