import React, { useEffect, useState } from 'react';

// import component
import arrowicon from '../../../images/arrowicon.svg';


// import images
import logo1 from '../../../images/airbluelogo.png';
import logo2 from '../../../images/egallogo.png';
import logo3 from '../../../images/emrateslogo.png';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Card, Spinner, Table } from 'react-bootstrap';
import BookingsTRLoop from '../booking-compo/BookingsTRLoop';
import BookingCardLoop from './BookingCardLoop';
import axios from 'axios';
import { API_BASE_URL, formatDate, getBearerToken } from '../../../functions';
import TicketCard from '../../PublicPages/componnents/TicketCard';
import { useAuth } from '../auth/AuthProvider';


const MySectorDetailPage = () => {
    const [mySectorData, setMySectorData] = useState('')
    const [loading, setLoading] = useState(false)
    const mysec = useParams()
    const { token } = useAuth();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    useEffect(() => {
        setLoading(true)
        axios.post(`${API_BASE_URL}/${token.role === 'admin' ? 'pnr-respect-to-mysector' : 'pnr-respect-to-mysector-user'}`, {
            my_sector_id: mysec.id
        }, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                // Handle the response data

                setMySectorData(response?.data?.data)
                setLoading(false)
            }).catch(error => {
                // Handle errors
                console.error(error);
            });
    }, [])

    // console.log(mysec);
    return (
        <>
            <div className={'main_heading mb-4'}>
                <h4>Booking List : <span className={'paragraphtext'}>{mysec.name}</span></h4>
                <Breadcrumb className={'BreadCrumbLinkks m-0 p-0'}>
                    <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/dashboard' }}>Dashboard</Breadcrumb.Item>
                    {/* <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/bookings/${mysec.id}` }}>Bookings</Breadcrumb.Item> */}
                    <Breadcrumb.Item active>{mysec.name}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <section className={'bookingPage'}>

                {mySectorData?.length > 0 ?
                    <>
                        {token.role === 'admin' ? <BookingCardLoop props={mySectorData} />
                            :
                            <>
                                {/* <div className='tobbar ticketbox flex-column flex-md-row mt-4'>
                                    <div className="airline">
                                        <div>Airline</div>
                                    </div>
                                    <div className="airdetails flex-column flex-md-row">
                                        <div>Sector/Date</div>
                                        <div>Timing</div>
                                        <div>Baggage/Meal</div>
                                        <div>Price</div>
                                    </div>
                                    <div className="bookbtn"></div>
                                </div> */}
                                <Table responsive="md" className='booking-table'>
                                    <thead>
                                        <tr>
                                            <th>Airline</th>
                                            <th>Sector/Date</th>
                                            <th className='text-center'>Timing</th>
                                            <th className='text-end'>Baggage/Meal</th>
                                            <th className='text-end'>Price</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <TicketCard data={mySectorData} />
                                    </tbody>
                                </Table>
                            </>}
                    </>
                    : loading ?
                        (
                            <div className={'mt-3 w-100 text-center'}>
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                            </div>
                        ) :
                        <div className="py-3"><p>No Data Found</p></div>}
            </section>
        </>
    )
}

export default MySectorDetailPage;