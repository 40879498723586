import React, { useState } from 'react'
import { Button, Col, Form, FormLabel, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../../functions';
import eye from "../../images/eye.svg";
import gmail from "../../images/gmail.svg";
import fbicon from "../../images/fbicon.svg";
import twittericon from "../../images/twittericon.svg";
import BookTicket from './BookTicket';
import toast from 'react-hot-toast';


const SigninPopup = ({ show, setShow, onLoginSuccess }) => {
    const handleClose = () => setShow(false);
    const [email, setemail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordType, setPasswordType] = useState(true);
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', JSON.stringify(data.success));
                handleClose();
                // Call onLoginSuccess when login is successful
                onLoginSuccess();
            } else {
                setError('Invalid email or password');
            }
        } catch (error) {
            setError('Something went wrong. Please try again later.');
            toast.error(error.response.data.message)
        }
    };
    return (
        <>
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="d-flex px-4 align-items-center">
                    <Modal.Title id="contained-modal-title-vcenter">Login to your portal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <Form className="validate-form w-100" onSubmit={(e) => handleLogin(e)}>
                        <span className="login100-form-title">You have to login firt to book ticket</span>
                        <p>New user? <Link to={'/signup'} className={'fontweight600 greentext fontsize14'}>Create an account</Link></p>
                        <Col xl={12} className="mb-4 mt-5">
                            <Form.Group>
                                <div className="wrap-input100 validate-input" data-validate="Email Address">
                                    <FormLabel className="0">Email Address</FormLabel>
                                    <input className="input100" placeholder="xyz@company.com" required type="email" autoComplete="on" id="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                    <span className="focus-input100"></span>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col xl={12} className="mb-3">
                            <Form.Group>
                                {/* <FormLabel htmlFor="password" className="mb-2">Password</FormLabel> */}
                                <div className="wrap-input100 validate-input" data-validate="Password is required">
                                    <input className="input100" type={passwordType ? "password" : "text"} id="password" required placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <span className="focus-input100"></span>
                                    <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                                </div>
                            </Form.Group>
                        </Col>
                        <div className="d-flex align-items-center mb-4">
                            <div className="contact100-form-checkbox">
                                <Form.Group className="mb-0 d-flex align-items-center">
                                    <Form.Check />
                                    <FormLabel label="inputPassword5" className="m-0 ms-3">Stay Log In</FormLabel>
                                </Form.Group>
                            </div>
                            <p className="ms-auto"><Link to={'/forgot'} className="txt1">Forgot password?</Link></p>
                        </div>
                        {error && <div className="redtext mb-2">{error}</div>}
                        <Button variant="" type="submit" id="submit" className="login100-form-btn">Login</Button>
                        <p className="mt-4 text-center">Don't have an account?<Link to={'/signup'} className="fontweight600 greentext fontsize14"> Sign Up</Link></p>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={gmail} alt={'icon'} /></Link>
                            <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={fbicon} alt={'icon'} /></Link>
                            <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={twittericon} alt={'icon'} /></Link>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SigninPopup
