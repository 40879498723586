import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Spinner, Table } from 'react-bootstrap';

// images import
import AgentTRLoop from './AgentTRLoop';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import axios from 'axios';
import ToastMessage from '../../ToastMessage';
import AddAgent from '../../Modals/AddAgent';

// images import
import arrowicon from '../../../images/arrowicon.svg';
import userlogo from '../../../images/avatarpic3.jpg';
import arrowlefticon from '../../../images/arrowlefticon.svg';

const Agents = () => {
  const [agent, setAgent] = useState()
  const [show, setShow] = useState(false)
  const [apiData, setApiData] = useState('')
  const [loading, setloading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);



  useEffect(() => {
    axios.get(`${API_BASE_URL}/listofagents?page=${currentPage}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      if (currentPage === 1) {
        setAgent(response?.data?.data?.data)
      } else {
        setAgent(preData => [...preData, ...response?.data?.data?.data]);
      }
      setloading(false)
      setApiData(response?.data?.data);
    }).catch(error => {
      // Handle errors
      console.error(error);
      setloading(false)
    });
  }, [currentPage])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handelshow = (e) => {
    e.preventDefault()
    setShow(true);
  }


  const nextPage = () => {
    if (apiData.next_page_url) {
      setCurrentPage(currentPage + 1);
      setloading(true)
    }
  };

  const prevPage = () => {
    if (apiData.prev_page_url) {
      setCurrentPage(currentPage - 1);
      setAgent(preData => preData.slice(0, -apiData.per_page));
      setloading(true)
    }
  };

  // console.log(agent);
  return (
    <>
      <div className={'main_heading mb-4 d-flex align-items-center'}>
        <h4>Agents:</h4>
        <Link to={'#'} className='ms-auto btn greenbg text-white fontsize14 fontweight700' style={{ minWidth: '160px', maxWidth: '160px' }} onClick={handelshow}>Add New Agent</Link>
      </div>
      {/*  */}
      <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
        <Card.Header className='px-3 px-lg-4 py-3' style={{ background: 'transparent', border: 'none', }}><h5 className={'fontsize18 blacktext fontweight600'}>Agents</h5></Card.Header>
        <Card.Body className={'p-0 mb-3'}>
          <Table responsive={'sm'} className={'mb-0 allView'}>
            <thead className={'thead-dark'}>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Number</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {agent && agent?.map((val, i) => {
                return (
                  <AgentTRLoop
                    source={'agent'}
                    key={i}
                    id={val?.id}
                    image={val?.image}
                    name={val.name}
                    email={val.email}
                    phone={val.phone}
                  />
                )
              })}
            </tbody>
          </Table>
          {!agent || loading && (
            <div className={'mt-3 w-100 text-center'}>
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            </div>
          )}
        </Card.Body>
        <Card.Footer className='px-3 px-lg-4 py-3 text-end' style={{ background: 'transparent', }}>
          <div className="d-flex align-items-center justify-content-end">
            <Badge bg={''} className={'p-2 py-1 gradiant2bg greentext'}>{agent && agent?.length} <span className='blacktext'>of</span> {apiData?.total}</Badge>
            <p className='p-0 m-0 fontsize12 blacktext fontweight500 mx-3'>Per Page : {apiData?.per_page} </p>
            <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={prevPage} disabled={!apiData.prev_page_url}><img className={'img-fluid'} src={arrowlefticon} alt={'icon'} /></Button>
            <span>{currentPage}</span>
            <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={nextPage} disabled={!apiData.next_page_url}><img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Button>
          </div>
        </Card.Footer>
      </Card>
      {show &&
        <AddAgent show={show} setShow={setShow} />
      }
    </>
  )
}

export default Agents;