import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Card, Col, Dropdown, Form, NavItem, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';



// import component
import arrowicon from '../../../../images/arrowicon.svg';
import verticalicon from '../../../../images/verticalicon.svg';
import { API_BASE_URL, getBearerToken, getLoadingState, setLoadingState } from '../../../../functions';
import ToastMessage from '../../../ToastMessage';
import BookingsTRLoop from '../../booking-compo/BookingsTRLoop';
import AccountsTRLoop from './AccountsTRLoop';
import { useAuth } from '../../auth/AuthProvider';

const Accounts = () => {
  const [payments, setPayments] = useState('')
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key
  const [loading, setLoading] = useState(false);
  const { token } = useAuth()
  const [reportQuery, setReportQuery] = useState(
    {
      from: '',
      to: '',
      type: null,
      selected_type: 'date',
      agent_id: null,
    }
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState('');
  const [searchResults, setSearchResults] = useState([]);





  const handelSearch = (e) => {
    e.preventDefault();
    setLoadingState(true, 'PAYMENTS_LIST');
    setLoading(true)
    const formdata1 = new FormData();

    formdata1.append('agent_id', reportQuery.agent_id)
    formdata1.append('type', reportQuery.type)
    formdata1.append('from', reportQuery.from)
    formdata1.append('to', reportQuery.to)
    axios.post(`${API_BASE_URL}/account-api`, formdata1, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setLoadingState(false, '');
      setPayments(response?.data?.data)
      setLoading(false)
    }).catch(error => {
      // Handle errors
      console.error(error);
      setLoadingState(false, '');
      setLoading(false)

    });
  };

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value)
    setLoading(true)
    searchTerm &&
      axios.post(`${API_BASE_URL}/searchagent`,
        {
          searchquery: searchTerm
        }, {
        headers: {
          'Authorization': `Bearer ${getBearerToken()}`
        }
      })
        .then(response => {
          // Handle the response data
          setSearchResults(response.data.data);
          setLoading(false)
        }).catch(error => {
          // Handle errors
          setLoading(false)
          console.error(error);
        });
  };
  const handleAgentSelection = (agent) => {
    setSearchResults([]);
    setSelected(true)
    setSearchTerm(agent?.name)
    setReportQuery({ ...reportQuery, agent_id: agent?.id })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    if (reportQuery.selected_type === 'date') {
      setReportQuery({ ...reportQuery, type: null })
    }
    if (token.role === 'user') {
    setReportQuery({ ...reportQuery, agent_id: token.agent_id })
  }
  }, [reportQuery.selected_type, reportQuery.agent_id])

  const handelSelect = (e) => {
    setReportQuery({ ...reportQuery, from: '', to: '', type: e, selected_type: e })
  }


  // console.log('payments', reportQuery);

  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        <h4>Payments</h4>
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      <Row className={'m-0 p-0'}>
        <Col className={'mb-4 mb-lg-0'} xxl={'12'} xl={'12'} lg={'12'}>
          <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
            <Card.Header className="px-3 px-lg-4 py-3" style={{ background: 'transparent', border: 'none' }}>
              {/* <h5 className="fontsize18 blacktext fontweight600">Date Range</h5> */}
              {token.role === 'admin' &&
                <>
                  <Form className='AllForm'>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label className='m-0'>Agent Name</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="text"
                          placeholder="Search Agent by name"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          onChange={handleSearch}
                          readOnly={selected}
                          value={searchTerm}
                          required
                        />
                        {selected &&
                          <Button
                            type={''}
                            variant={''}
                            className={'fontweight700 paragraphbg fontsize14 whitetext ms-3 '}
                            onClick={() => {
                              setSearchTerm('');
                              setSelected(false);
                              setReportQuery({ ...reportQuery, agent_id: null })
                            }}
                          >
                            Change Agent
                          </Button>
                        }
                      </div>
                    </Form.Group>
                    {searchTerm && !selected && (
                      <div className='searchdropdow mt-1'>
                        {loading ? (
                          <div className={'mt-3 w-100 text-center'}>
                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                          </div>
                        ) : (
                          <>
                            {searchResults.length > 0
                              ? searchResults.map((agent) => (
                                <NavItem
                                  className=''
                                  key={agent.id}
                                  onClick={() => handleAgentSelection(agent)}
                                >
                                  <p className='p-3'>{agent.name}</p>
                                </NavItem>
                              ))
                              : !selected && 'No Agent Found'}
                          </>
                        )}
                      </div>
                    )}
                  </Form>
                </>
              }
              {(reportQuery.agent_id || token.role === 'user') &&
                <Form className='AllForm' onSubmit={handelSearch}>
                  <div className="d-flex align-items-center mt-3">
                    <Form.Group className="me-4 ms-1 form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label className='m-0'>Search type</Form.Label>
                      <Form.Select aria-label="Default select example"
                        as="select"
                        required
                        onChange={(e) => handelSelect(e.target.value)}
                      >
                        <option className='blacktext' value={'date'}>Date range</option>
                        <option className='blacktext' value={'all'}>Over all</option>
                      </Form.Select>
                    </Form.Group>
                    {reportQuery.selected_type === 'date' &&
                      <>
                        <Form.Group className="me-4 ms-1 form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label className='m-0'>From</Form.Label>
                          <Form.Control type="date" name={''} id={''} placeholder={'date'} required
                            onChange={(e) => setReportQuery({ ...reportQuery, from: e.target.value })}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group className="me-4 ms-1 form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label className='m-0'>To</Form.Label>
                          <Form.Control type="date" name={''} id={''} placeholder={'date'} required
                            onChange={(e) => setReportQuery({ ...reportQuery, to: e.target.value })}
                          ></Form.Control>
                        </Form.Group>
                      </>}
                    <Button variant="" type='submit' disabled={(!reportQuery.from || !reportQuery.to) && reportQuery.type !== 'all'} className="w-100px greenbg whitetext fontsize14 fontweight700 me-3">
                      {loading ?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        : 'Search'
                      }
                    </Button>
                  </div>
                </Form>}
            </Card.Header>
            {payments && <>
              <Card.Body className="p-0 mb-3">
                <AccountsTRLoop
                  data={payments}
                  toastMessage={toastMessage}
                  setToastMessage={setToastMessage}
                  isLoading={isLoading}
                  loadingKey={loadingKey}
                  showToast={showToast}
                  setShowToast={setShowToast} />
                {/* {loading && bookings.length > 0 && (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>)} */}
              </Card.Body>
              {/* <Card.Footer className="px-3 px-lg-4 py-3 text-end" style={{ background: 'transparent' }}>
                <Link to="#" className="fontsize14 blacktext fontweight600 text-decoration-none">
                  View All <img className="img-fluid" src={arrowicon} alt="icon" />
                </Link>
              </Card.Footer> */}
            </>}
          </Card>
        </Col>
      </Row >
    </>
  )
}

export default Accounts;