import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';

// import images
import verticalicon from '../.././../images/verticalicon.svg';
import axios from 'axios';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import ToastMessage from '../../ToastMessage';
import AddAgent from '../../Modals/AddAgent';
import toast from 'react-hot-toast';
import UpdateProfile from '../../Modals/UpdateProfile';
import UserProfile from '../../Modals/UserProfile';

const AgentTRLoop = (props) => {
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const [show, setShow] = useState(false)
  const [target, setTarget] = useState({ name: '', id: null })
  const [loading, setloading] = useState(false)


  const handalClick = (e, target) => {
    // setloading(true)
    const ToastLd = toast.loading(`Loading...`)
    axios.get(`${API_BASE_URL}/${target === 'accept' ? 'approve-user' : 'reject-user'}/${e}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      toast.remove(ToastLd)
      // setToastMessage({ type: 'success', message: `User ${target === 'accept' ? 'Approved' : 'Rejected'} successfully!` });
      {
        target === 'accept' ?
          toast.success(`User approved successfully!`, {
            duration: 2000
          })
          :
          toast.error(`User rejected successfully!`, {
            duration: 2000
          })
      }
      // setloading(false)
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }).catch(error => {
      // Handle errors
      toast.remove(ToastLd)
      // setloading(false)
      // setToastMessage({ type: 'failde', message: error.response.data.message });
      // setShowToast('show')
    });

  };


  const handalDelet = (e, id) => {
    axios.get(`${API_BASE_URL}/deleteagent/${e}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      // setToastMessage({ type: 'success', message: '' });
      toast('Agent Deleted successfully!', { duration: 2000 })
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }).catch(error => {
      // Handle errors
      toast(error.response.data.message, { duration: 2000 })
      // setToastMessage({ type: 'failde', message: error.response.data.message });
      // setShowToast('show')
    });

  };

  const handalEdit = (id, t) => {
    setShow(true)
    setTarget({ ...target, name: t, id: id })
  };
  // console.log(props);
  return (
    <>
      {/* {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />} */}
      <tr>
        <td>
          <Link to={'#'} className={'d-flex align-items-center text-decoration-none'} onClick={(e) => handalEdit(props?.id, 'profile')}>
            <div className='AllPhoto'><img className={'img-fluid'} src={props.image} alt={'icon'} /></div>
            <h5 className={'ms-3 fontsize14 fontweight600 blacktext'}>{props.name}</h5>
          </Link>
        </td>
        <td>{props.email}</td>
        {props.agency_name && <td>{props.agency_name}</td>}
        <td>{props.phone || props.role}</td>
        {props.created_at && <td>{props.created_at}</td>}
        <td><Badge bg={''} className={`p-2 py-1 ${props.active_status === 'rejected' ? 'errorlighterbg redtext' : props.active_status === 'pending' ? 'warninglighterbg yellowtext' : 'successcolorbg whitetext'}`}>{props.active_status || 'Active'}</Badge></td>
        <td>
          <Dropdown className={''} align={'end'} autoClose="outside">
            <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside">
              <img className={'img-fluid'} src={verticalicon} alt={'icon'} />
              {/* <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {props.source === 'users' &&
                <>
                  <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalClick(props?.id, 'reject')}>Reject</Link>
                  <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalClick(props?.id, 'accept')}>Accept</Link>
                  <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalEdit(props?.agent_id, 'editProfile')}>Edit Profile</Link>
                </>}
              {props.source === 'agent' &&
                <>
                  <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalEdit(props?.id, 'addAgent')}>Edit</Link>
                  <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalDelet(props?.id)}>Delete</Link>
                </>}
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
      {(show && target.name === 'addAgent') &&
        <AddAgent show={show} setShow={setShow} editData={props} />
      }
      {(show && target.name === 'editProfile') &&
        <UpdateProfile show={show} setShow={setShow} path='admin' agent_id={target.id} />
      }
      {(show && target.name === 'profile') &&
        <UserProfile show={show} setShow={setShow} data={props.agent} size='xl' />
      }

    </>
  )
}

export default AgentTRLoop;