import axios from 'axios';
import React, { useState } from 'react'
import { Badge, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { API_BASE_URL, getBearerToken } from '../../../functions';


// import images
import verticalicon from '../.././../images/verticalicon.svg';
import { useAuth } from '../auth/AuthProvider';
import toast from 'react-hot-toast';

export const PaymentList = (props) => {
    const [toastMessage, setToastMessage] = useState(null);
    const [show, setShow] = useState(false)
    const { token } = useAuth()


    const handalDelet = (e, id) => {
        axios
            .post(`${API_BASE_URL}/delete-booking-payment`,
                {
                    payment_id: e
                }, {
                headers: {
                    'Authorization': `Bearer ${getBearerToken()}`
                }
            }).then(response => {
                // Handle the response data
                toast.success(response.data.message)
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }).catch(error => {
                // Handle errors
                toast.error(error.response.data.message)
            });

    };


    const handalEdit = (e, id) => {
        setShow(true)
    };

    // console.log("data", props);
    return (
        <>
            <tr>
                {/* <td>
                    <Link to={'#'} className={'d-flex align-items-center text-decoration-none'}>
                        <div className='AllPhoto'><img className={'img-fluid'} src={props.logo} alt={'icon'} /></div>
                    </Link>
                </td> */}
                <td>
                    {props?.added_by}
                    {props?.delete_by && <br />}
                    {props?.delete_by && props.delete_by}
                    {props?.edit_by && <br />}
                    {props?.edit_by && props.edit_by}
                </td>
                <td>{props?.receive_amount}</td>
                {/* <td><Badge bg={''} className={`p-2 py-1 ${props.status == 1 ? 'successcolorbg whitetext' : 'errorlighterbg redtext'}`}>{props.status == 1 ? "Active" : "Inactive"}</Badge></td> */}
                <td>{props?.receive_date}</td>
                <td>{props?.text}</td>
                {token.role === 'admin' &&
                    <td>
                        <Dropdown className={''} align={'end'} autoClose="outside">
                            <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalEdit(props?.id)}>Edit</Dropdown.Item>
                                <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalDelet(props?.id)}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </td>
                }
            </tr>
        </>
    )
}
