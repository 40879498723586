import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken } from '../../functions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';

const AddAgent = ({ show, setShow, path = '', editData }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    // const Navigate = useNavigate();

    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        image: null,
    });

    // Add a state to track whether the modal is in edit mode or not
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        // If editData is provided, update the data state with the editData
        if (editData) {
            setData({ ...editData });
            setEditMode(true);
        } else {
            // If editData is not provided, reset the data state for adding a new agent
            setData({
                name: '',
                email: '',
                phone: '',
                image: null,
            });
            setEditMode(false);
        }
    }, [editData]);
    // console.log(editData);
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();

        formData.append('name', data.name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('image', data.image);

        if (editMode) {
            // Handle edit operation
            // Call API to update existing agent
            axios
                .post(`${API_BASE_URL}/updateagent/${editData.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${getBearerToken()}`,
                    },
                })
                .then((response) => response)
                .then((result) => {
                    setToastMessage({
                        type: 'success',
                        message: 'Agent updated successfully!',
                        show: 'show',
                    });
                    setLoading(!loading);
                    handleClose();
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(!loading);
                });
        } else {
            // Handle add operation
            // Call API to submit data for adding a new agent
            axios
                .post(`${API_BASE_URL}/add-agent`, formData, {
                    headers: {
                        Authorization: `Bearer ${getBearerToken()}`,
                    },
                })
                .then((response) => response)
                .then((result) => {
                    setToastMessage({
                        type: 'success',
                        message: 'Agent added successfully!',
                        show: 'show',
                    });
                    path !== 'booking' && window.location.reload();
                    setLoading(!loading);
                    handleClose();
                })
                .catch((error) => {
                    setToastMessage({ type: 'error', message: error });
                    setLoading(!loading);
                });
        }
    };

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit}>

                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">{editMode ? 'Edit Agent' : 'Add Agent'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        <Row className={'my-4'}>
                            <Col className={'mb-4'} xl={'12'} lg={'12'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Agent Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name={''}
                                        id={''}
                                        placeholder={'Name'}
                                        value={data.name} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, name: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        name={''}
                                        id={''}
                                        placeholder={'Contact Number'}
                                        value={data.phone} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, phone: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email (optional)</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name={''}
                                        id={''}
                                        placeholder={'Email'}
                                        value={data.email} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, email: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'12'} lg={'12'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Image/Logo</Form.Label>
                                    {(!(data?.image === editData?.image) || !data?.image ) ?
                                        <Form.Control
                                            required={!editData?.image} // Mark as required only when adding a new agent
                                            type="file"
                                            name={''}
                                            id={''}
                                            placeholder={'Agent image/logo'}
                                            onChange={(e) => setData({ ...data, image: e.target.files[0] })}
                                        />
                                        :
                                        <div className="d-flex align-items-center">
                                            <img src={data?.logo} alt="Agent" width="100" height="100" className="me-2" />
                                            <Button variant="outline-danger" size="sm" onClick={() => setData({ ...data, image: null })}>
                                                Remove Image
                                            </Button>
                                            {/* // Check if data.image is falsy (no image is present) */}

                                        </div>
                                    }
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    {/*  */}
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : editMode ? 'Update' : 'Save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddAgent;