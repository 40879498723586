import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, countries, getBearerToken, handleMouseWheel } from '../../functions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import { useAuth } from '../pages/auth/AuthProvider';
import eye from "../../images/eye.svg";
import toast from 'react-hot-toast';


const Alert = ({ show, setShow, path = '' }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { token } = useAuth()
    const [data, setData] = useState(
        {
            agentid: token.agent_id,
            w_number: '',
            code: ''
        }
    );
    const [passwordType, setPasswordType] = useState(true);
    const [showChnage, sethowChnage] = useState(false)
    const [error, setError] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const { updateTokenVAlue } = useAuth();
    const Navigate = useNavigate();



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const formdata1 = new FormData();
        formdata1.append('agent_id', data.agentid)
        formdata1.append('phone', data.w_number)
        formdata1.append('code', data.code)

        axios.post(`${API_BASE_URL}/updateandverifywhp`, formdata1, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // console.log("in response", response);
            // Handle the response data
            setLoading(false);
            toast.success(response.data.message);
            updateTokenVAlue('w_verified', false)
            handleClose()
            setTimeout(() => {
                localStorage.removeItem('token');
                Navigate('/signin')
            }, 3000);
        }).catch(error => {
            // Handle errors
            setLoading(false); // Set loading state to false in case of error
            toast.error(error.response.data.message)
        });
    };

    const sendWhCode = (e) => {
        e.preventDefault();
        setLoading(true)

        const formdata1 = new FormData();
        formdata1.append('phone', data.w_number)
        formdata1.append('agent_id', data.agentid)

        axios.post(`${API_BASE_URL}/sendwhatsappcode`, formdata1, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // console.log("in response", response);
            // Handle the response data
            setLoading(false);
            setCodeSent(true)
            toast.success(response.data.message);
        }).catch(error => {
            // Handle errors
            setLoading(false); // Set loading state to false in case of error
            toast.error(error.response.data.message)
        });
    };


    const handleChangeNumber = (e) => {
        setCodeSent(false)
    }

    const handleInputChange = (e) => {
        const input = e.target.value;
        const regex = /^\+923\d{9}$/;

        if (regex.test(input)) {
            setError('');
        } else {
            setError('Invalid format. Please use +923XXXXXXXXX');
        }

        setData({ ...data, w_number: input })
    };

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('token');
        Navigate('/signin')

    }

    // console.log("token.agent_id");

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton={false} className="d-flex px-4 align-items-center">
                    <Modal.Title id="contained-modal-title-vcenter" className='redtext'>Verify your Whatsapp Number</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <p>You need to verify your WhatsApp number so that we can send you booking details to your verified WhatsApp number. Thanks</p>
                    <div className="rounded mt-2 p-3 RGBAcolor1bg ">
                        <Form className={'AllForm'}>
                            <Row>
                                {!codeSent ? <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label>WhatsApp Number</Form.Label>
                                        <Form.Control
                                            required
                                            type={"text"}
                                            value={data.w_number}
                                            onChange={handleInputChange}
                                            placeholder="+923XXXXXXXXX"
                                        />
                                        {error && <p style={{ color: 'red' }}>{error}</p>}
                                    </Form.Group>
                                </Col> : <p style={{ color: 'blacktext', fontSize: '14px' }} className='m-0'>Code has been sent to {data.w_number}. Please check.</p>}
                                <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                    {!error && data.w_number ?
                                        <Button
                                            variant={''} disabled={(data.w_number === '')}
                                            className={`btn ${codeSent ? 'bluetext' : 'greenbg text-white'}  fontsize14 fontweight700`}
                                            onClick={(e) => sendWhCode(e)}
                                        >
                                            {loading ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                : !codeSent ? 'Send Code' : 'Send Agein'
                                            }</Button> : null}
                                    {codeSent ? <Button
                                        variant={''} disabled={(data.w_number === '')}
                                        className={`btn ${codeSent ? 'bluetext' : 'greenbg text-white'}  fontsize14 fontweight700`}
                                        onClick={(e) => handleChangeNumber(e)}
                                    >
                                        Change Number
                                    </Button> : null}
                                </Col>
                                <Row>
                                    {codeSent ? <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Enter code</Form.Label>
                                            <Form.Control
                                                required
                                                type={"number"}
                                                onWheel={handleMouseWheel}
                                                value={data.code}
                                                onChange={(e) => setData({ ...data, code: e.target.value })}
                                                placeholder="XXXX"
                                            />
                                        </Form.Group>
                                    </Col> : null}
                                </Row>
                                <div className="d-flex">
                                    {data?.code ?
                                        <Button variant={''}
                                            disabled={(data.new_password === '' || data.w_number === '')}
                                            className='btn greenbg text-white fontsize14 fontweight700 me-3'
                                            onClick={(e) => handleSubmit(e)}
                                        >
                                            {loading ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                : 'Save'
                                            }</Button> : null}
                                    <Button variant={''} className='' onClick={(e) => logout(e)}>Logout</Button>
                                </div>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Alert;