import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Spinner, Table } from 'react-bootstrap';

// import images
import verticalicon from '../../../../images/verticalicon-dots.svg';
import { API_BASE_URL, formatDate, getBearerToken, setLoadingState } from '../../../../functions';
import axios from 'axios';
import { useAuth } from '../../auth/AuthProvider';

const AgentBlncTRLoop = ({
    data,
    isLoading,
    loadingKey,
    toastMessage,
    setToastMessage,
    showToast,
    setShowToast }) => {

    const [show, setShow] = useState(false)
    const [showCon, setShowCon] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [targetBooking, setTargetBooking] = useState('')
    const { token } = useAuth()



    const handalPayment = (booking) => {
        setTargetBooking(booking)
        setShow(true)
    }
    const handalCancel = (e, id) => {
        setLoadingState(true, 'CANCEL_BOOKING');
        axios.get(`${API_BASE_URL}/cancelbooking/${e}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            setLoadingState(false, '');
            setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
            setTimeout(() => {
                window.location.reload();
            }, 2000);

        }).catch(error => {
            // Handle errors
            setToastMessage({ type: 'failde', message: error.response.data.message });
            setShowToast('show')
            setLoadingState(false, '');


        });

    };

    const handalEdit = (id) => {
        setShowEdit(true)
        setTargetBooking(id)
    }
    const handalShow = (booking) => {
        setShowCon(true);
        setTargetBooking(booking)
    }

    // console.log(data);
    return (
        <>
            <Table responsive="sm" className="mb-0 reports">
                <thead className="thead-dark">
                    <tr>
                        <th>Sr. #</th>
                        <th>Agent Id</th>
                        <th>Agent</th>
                        <th>Last Paid</th>
                        <th>Balance</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                {data?.length <= 0 ? (
                    <tr>
                        <td colSpan={7} className="pt-3 w-100 text-center">
                            No Record found.
                        </td>
                    </tr>
                ) :
                    <tbody>
                        {data?.map((val, index) => (
                            <tr key={val.agent_id}>
                                <td>{index+1}</td>
                                <td>
                                    <Link to={`#`} className="text-decoration-none bluetext fontweight600">
                                        Agent-{val.agent_id}
                                    </Link>
                                </td>
                                <td>
                                    {val.agent_name}
                                </td>
                                <td>{formatDate(val.last_payment_date)}</td>
                                <td>{val.balance}</td>
                                {/* <td>
                                <Dropdown className="" align="end" autoClose="outside">
                                    <Dropdown.Toggle className="p-0 m-0 " variant="" id="dropdown-autoclose-outside">
                                        <img className="img-fluid" src={verticalicon} alt="icon" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/dashboard/book/${val?.id}`} className="dropdown-item">
                                            View
                                        </Link>
                                        <Link to={`#`} className="dropdown-item" onClick={(e) => handalPayment(val)}>
                                            Add Payment
                                        </Link>
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalShow(val)}>
                                            {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                :
                                                'Confirm Booking'
                                            }
                                        </Link>
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(val.id)}>
                                            {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                :
                                                'Cancel Booking'
                                            }
                                        </Link>
                                        <Link to={`/dashboard/pnr/${val.pnr}`} className="dropdown-item">
                                            Open full PNR
                                        </Link>
                                        <Link to={`#`} className="dropdown-item" onClick={(e) => handalEdit(val.id)}>
                                            Edit Booking
                                        </Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td> */}
                            </tr>
                        ))}
                    </tbody>}
            </Table>
            {show &&
                <AddTicketPay editData={targetBooking} show={show} setShow={setShow} />
            }
            {showCon &&
                <ConfirmBooking show={showCon} setShow={setShowCon} path='booking-confirm' ConData={targetBooking} />
            }
            {showEdit &&
                <EditTicket id={targetBooking} show={showEdit} setShow={setShowEdit} />
            }
        </>
    )
}

export default AgentBlncTRLoop;