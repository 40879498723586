import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Card, Col, Dropdown, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';



// import component
import arrowicon from '../../../../images/arrowicon.svg';
import verticalicon from '../../../../images/verticalicon.svg';
import { API_BASE_URL, getBearerToken, getLoadingState, logout, setLoadingState } from '../../../../functions';
import ToastMessage from '../../../ToastMessage';
import BookingsTRLoop from '../../booking-compo/BookingsTRLoop';
import UpdateProfile from '../../../Modals/UpdateProfile';
import eye from "../../../../images/eye.svg";
import toast from 'react-hot-toast';
import { useAuth } from '../../auth/AuthProvider';


const AgentProfile = () => {
  const [payments, setPayments] = useState('')
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState('show')
  const [showChnage, sethowChnage] = useState(false)
  const [toastMessage, setToastMessage] = useState(null);
  const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const [data, setData] = useState(
    {
      email: token.email,
      old_password: '',
      new_password: ''
    }
  );
  const [passwordType, setPasswordType] = useState(true);
  const Navigate = useNavigate();


  const logout = (e) => {
    e.preventDefault()
    localStorage.removeItem('token');
    Navigate('/signin')

  }




  const handelShow = (e) => {
    e.preventDefault();
    setShow(true)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    const formdata1 = new FormData();
    formdata1.append('old_password', data.old_password)
    formdata1.append('new_password', data.new_password)
    formdata1.append('email', data.email)

    axios.post(`${API_BASE_URL}/changeuserpassword`, formdata1, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      toast.success(response.data.message);
      setLoading(false);
      setData({ ...data, old_password: '', new_password: '' })
      sethowChnage(!showChnage)
    }).catch(error => {
      // Handle errors
      toast.error(error.response.data.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handelChnage = () => {
    sethowChnage(!showChnage)
  }


  // console.log('payments', data);

  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        <h4>Agent Profile</h4>
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      <Row className={'m-0 p-0'}>
        <Col className={'mb-4 mb-lg-0 mx-auto'} xxl={'8'} xl={'8'} lg={'8'}>
          <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
            <Card.Header className="px-3 px-lg-4 py-3 text-center" style={{ background: 'transparent', border: 'none' }}>
              <h5 className="fontsize18 blacktext fontweight600">Profile Settings</h5>
            </Card.Header>
            <Card.Body className="px-3 py-0 mb-3">
              <div className='d-flex align-items-center justify-content-between rounded p-2 RGBAcolor1bg mb-4'>
                <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className={'AllPhoto'}><img className="img-fluid" src={token.image} alt="icon" /></div>
                  <div className={'ms-3 hidetext'}>
                    <h4 className={'fontsize14 fontweight600 blacktext'}>{token.name}</h4>
                    <p className={'m-0 fontsize14 paragraphtext'}>{token.role}</p>
                  </div>
                </div>
                <div className=''>
                  <Button variant={''} className='btn greenbg text-white fontsize14 fontweight700' onClick={handelShow}>Edit</Button>
                </div>
              </div>
              <div className='mt-2'>
                {!showChnage ?
                  <Button variant={''} className={''} onClick={handelChnage}>Change Password</Button>
                  : <div className="rounded mt-2 p-3 RGBAcolor1bg ">
                    <Row>
                      <Form className={'AllForm'} onSubmit={handleSubmit}>
                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Old Password</Form.Label>
                            <Form.Control
                              required
                              type={passwordType ? "password" : "text"}
                              placeholder='Old Password'
                              value={data.old_password}
                              onChange={(e) => setData({ ...data, old_password: e.target.value })}
                            />
                            <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'6'} lg={'6'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              required
                              type={passwordType ? "password" : "text"}
                              value={data.new_password}
                              placeholder='New Password'
                              onChange={(e) => setData({ ...data, new_password: e.target.value })}
                            />
                            <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                          </Form.Group>
                        </Col>
                        <div className="d-flex">
                          <Button variant={''} disabled={(data.new_password === '' || data.old_password === '')} className='btn greenbg text-white fontsize14 fontweight700 me-3' type='submit'>
                            {loading ?
                              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                              : 'Save'
                            }</Button>
                          <Button variant={''} className='btn btn-danger text-white fontsize14 fontweight700' type='submit' onClick={handelChnage}>Cancel</Button>
                        </div>
                      </Form>
                    </Row>
                  </div>}
              </div>
              <div className='mt-2'>
                <Button variant={''} className='' onClick={(e) => logout(e)}>Logout</Button>
              </div>
            </Card.Body>
            {/* <Card.Footer className="px-3 px-lg-4 py-3 text-end" style={{ background: 'transparent' }}>
              <Link to="#" className="fontsize14 blacktext fontweight600 text-decoration-none">
                View All <img className="img-fluid" src={arrowicon} alt="icon" />
              </Link>
            </Card.Footer> */}
          </Card>
        </Col>
      </Row>
      {show &&
        <UpdateProfile show={show} setShow={setShow} path='agent' />
      }
    </>
  )
}

export default AgentProfile;