import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Col, Button, FormLabel, Spinner } from "react-bootstrap";

// import logo_black from "";
import eye from "../../images/eye.svg";
import signBanner from "../../images/signBanner.svg";
import logo from "../../images/logo.png";
import gmail from "../../images/gmail.svg";
import fbicon from "../../images/fbicon.svg";
import twittericon from "../../images/twittericon.svg";
import { API_BASE_URL } from '../../functions';
import { useAuth } from './auth/AuthProvider';
import toast from 'react-hot-toast';

const SignIn = ({ setIsAuthenticated }) => {

  const [email, setemail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { updateToken } = useAuth();

  const Navigate = useNavigate()
  useEffect(() => {
    let isLogin = localStorage.getItem('token');
    if (isLogin) {
      Navigate('/dashboard');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        // localStorage.setItem('token', JSON.stringify(data.success));
        updateToken(data.success);
        (data?.success?.role === 'admin' ? Navigate('/dashboard') : Navigate('/dashboard'))
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Something went wrong. Please try again later');
      }

    } catch (error) {
      toast.error(error.response.data.message)
      setError('Something went wrong. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // console.log(passwordType);
  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <Link to={'/'} className={'text-start w-100 d-block d-xl-none px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
            <div className="login100-form">
              <Form className="validate-form w-100" onSubmit={(e) => handleLogin(e)}>
                <span className="login100-form-title">Log-In to Dhillu and Sahi Travels</span>
                <p>New user? <Link to={'/signup'} className={'fontweight600 greentext fontsize14'}>Create an account</Link></p>
                <Col xl={12} className="mb-4 mt-5">
                  <Form.Group>
                    <div className="wrap-input100 validate-input" data-validate="Email Address">
                      <FormLabel className="0">Email Address</FormLabel>
                      <input className="input100" placeholder="xyz@company.com" required type="email" autoComplete="on" id="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                      <span className="focus-input100"></span>
                    </div>
                  </Form.Group>
                </Col>
                <Col xl={12} className="mb-3">
                  <Form.Group>
                    {/* <FormLabel htmlFor="password" className="mb-2">Password</FormLabel> */}
                    <div className="wrap-input100 validate-input" data-validate="Password is required">
                      <input className="input100" type={passwordType ? "password" : "text"} id="password" required placeholder="Password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                      <span className="focus-input100"></span>
                      <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                    </div>
                  </Form.Group>
                </Col>
                <div className="d-flex align-items-center mb-4">
                  <div className="contact100-form-checkbox">
                    <Form.Group className="mb-0 d-flex align-items-center">
                      <Form.Check />
                      <FormLabel label="inputPassword5" className="m-0 ms-3">Stay Log In</FormLabel>
                    </Form.Group>
                  </div>
                  <p className="ms-auto"><Link to={'/forgot'} className="txt1">Forgot password?</Link></p>
                </div>
                {error && <div className="redtext mb-2">{error}</div>}
                <Button variant="" type="submit" id="submit" className="login100-form-btn" disabled={loading}>{!loading ? 'Login' : <Spinner as='span' animation='border' size='md' role='status' aria-hidden='true' />}</Button>
                <p className="mt-4 text-center">Don't have an account?<Link to={'/signup'} className="fontweight600 greentext fontsize14"> Register</Link></p>
                {/* <div className={'d-flex align-items-center justify-content-center'}>
                  <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={gmail} alt={'icon'} /></Link>
                  <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={fbicon} alt={'icon'} /></Link>
                  <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={twittericon} alt={'icon'} /></Link>
                </div> */}
              </Form>
            </div>
            <div className="login100-more">
              <Link to={'/'} className={'text-start w-100 d-block px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
              <div className={'h-100 d-flex align-items-center justify-content-center'} style={{ gap: '80px' }}>
                <div>
                  <h5 className={'mb-lg-5 loginTitle'}>Hi, Welcome back</h5>
                  <div className="trevelAgency-logo text-center"><img src={signBanner} className={'img-fluid'} alt={'icon'} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn;