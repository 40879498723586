import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown } from 'react-bootstrap';

// import images
import verticalicon from '../.././../images/verticalicon.svg';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import axios from 'axios';
import ToastMessage from '../../ToastMessage';
import AddAirline from '../../Modals/AddAirline';

const AirLinesTRLoop = (props) => {
    const [toastMessage, setToastMessage] = useState(null);
    const [show, setShow] = useState(false)


    const handalDelet = (e, id) => {
        axios.get(`${API_BASE_URL}/deleteairline/${e}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            setToastMessage({ type: 'success', message: 'Air Deleted successfully!', show: 'show' });
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(error => {
            // Handle errors
            console.error(error);
        });

    };


    const handalEdit = (e, id) => {
        setShow(true)
    };
    // console.log(props);


    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <tr>
                <td>
                    <Link to={'#'} className={'d-flex align-items-center text-decoration-none'}>
                        <div className='AllPhoto'><img className={'img-fluid'} src={props.logo} alt={'icon'} /></div>
                        {/* <h5 className={'ms-3 fontsize14 fontweight600 blacktext'}>{props.userTitle}</h5> */}
                    </Link>
                </td>
                <td>{props.full_name}</td>
                <td>{props.short_name}</td>
                <td><Badge bg={''} className={`p-2 py-1 ${props.status == 1 ? 'successcolorbg whitetext' : 'errorlighterbg redtext'}`}>{props.status == 1 ? "Active" : "Inactive"}</Badge></td>
                <td></td>
                <td></td>
                <td>
                    <Dropdown className={''} align={'end'} autoClose="outside">
                        <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalEdit(props?.id)}>Edit</Dropdown.Item>
                            <Dropdown.Item className={'dropdown-item'} onClick={(e) => handalDelet(props?.id)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
            {show &&
                <AddAirline setShow={setShow} show={show} editData={props} />
            }
        </>
    )
}

export default AirLinesTRLoop;