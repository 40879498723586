import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import userlogo from '../../../images/avatarpic3.jpg';
import UsersTRLoop from './UsersTRLoop';




const users = [
  {
    key: '0',
    id: '0',
    usericon: userlogo,
    name: 'Lucian Obrien',
    email: 'emai@gmail.com',
  },
  {
    key: '1',
    id: '1',
    usericon: userlogo,
    name: 'Lucian Obrien',
    email: 'emai@gmail.com',
  },
  {
    key: '2',
    id: '2',
    usericon: userlogo,
    name: 'Lucian Obrien',
    email: 'emai@gmail.com',
  },
]

const settings = () => {
  return (
    <>
      <div className={'main_heading mb-4 d-flex align-items-center'}>
        <h4>Settings</h4>

      </div>
      {/*  */}
      <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
        <Card.Header className='px-3 px-lg-4 py-3' style={{ background: 'transparent', border: 'none', }}>
          <div className={'main_heading mb-4 d-flex align-items-center'}>
            <h5 className={'fontsize18 blacktext fontweight600'}>User Management</h5>
            <Link to={'#'} className='ms-auto btn greenbg text-white fontsize14 fontweight700' style={{ minWidth: '160px', maxWidth: '160px' }} >Add New User</Link>
          </div>
        </Card.Header>
        <Card.Body className={'p-0 mb-3'}>
          <Table responsive={'sm'} className={'mb-0 allView'}>
            <thead className={'thead-dark'}>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((val) => {
                return (
                  <UsersTRLoop
                    key={val.key}
                    id={val.id}
                    usericon={val.image}
                    userTitle={val.name}
                    email={val.email}
                    agent={val.agent}
                  />
                )
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  )
}

export default settings;