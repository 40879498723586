import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Nav, Badge } from 'react-bootstrap';


// import compomet
import dashboardicon from "../images/sidebar_dashboard.svg";
import bookingicon from "../images/sidebar_booking.svg";
import paymenticon from "../images/sidebar_payment.svg";
import sectorsicon from "../images/sidebar_sectors.svg";
import sectorsicon1 from "../images/sidebar_sectors1.svg";
import settingicon from "../images/sidebar_setting.svg";
import airlinesicon from "../images/sidebar_airlines.svg";
// import groupsicon from "../images/sidebar_groups.svg";
import agentsicon from "../images/sidebar_agents.svg";
import grouphistoryicon from "../images/sidebar_grouphistory.svg";
import avatar from '../images/avatarpic3.jpg';
import { useAuth } from './pages/auth/AuthProvider';
import Dropdown from './common-Components/Dropdown';

const Sidebar = () => {
  // const { slug } = useParams();
  const location = useLocation();
  const isActive = (url) => {
    return location.pathname === url;
  }
  const { token } = useAuth();
  // console.log("isActive", location.pathname);
  return (
    <>
      <div id="sidebar" className="active left_side_menu">
        <div className="list-unstyled slimScrollDiv">
          <Link to={'/dashboard/my-profile'} >
            <div className='d-flex align-items-center justify-content-center justify-content-md-start p-2 RGBAcolor1bg mb-4 rounded'>
              <div className={'AllPhoto'}><img className="img-fluid" src={token.image || avatar} alt="icon" /></div>
              <div className={'ms-3 hidetext'}>
                <h4 className={'fontsize14 fontweight600 blacktext'}>{token.name}</h4>
                <p className={'m-0 fontsize14 paragraphtext'}>{token.role}</p>
              </div>
            </div>
          </Link>
          {/* General */}
          <p className={'hidetext mb-2 fontweight600 paragraphtext fontsize11 text-uppercase'}>General</p>
          <Nav.Item className="mb-2">
            <Link to={'/dashboard'} className={`nav-link ${isActive('/dashboard') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={dashboardicon} alt="icon" /></p><div className="hidetext ms-2">Dashboard</div></Link>
          </Nav.Item>
          <Dropdown isactivecheck={''} link={'#'} icon={paymenticon} text={'Bookings'} isActive={isActive}
            items={[
              { isactivecheck: '/dashboard/bookings', link: 'bookings', text: 'All Bookings' },
              { isactivecheck: '/dashboard/pendingbookings', link: 'pendingbookings', text: 'Unpaid' },
              // { isactivecheck: '/dashboard/cancelbookings', link: 'cancelbookings', text: 'Cancelled' },
            ]} />

          {token.role === 'user' &&
            <Nav.Item className="mb-2">
              <Link to={'accounts'} className={`nav-link ${isActive('/dashboard/accounts') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={paymenticon} alt="icon" /></p><div className="hidetext ms-2">My Ledger</div></Link>
            </Nav.Item>}
          {/* Management */}
          {token.role === 'admin' &&
            <>
              <Dropdown isactivecheck={''} link={'#'} icon={paymenticon} text={'Accounts'} isActive={isActive}
                items={[
                  { isactivecheck: '/dashboard/payments', link: 'payments', text: 'General Report' },
                  { isactivecheck: '/dashboard/agents-blnc', link: 'agents-blnc', text: 'Agents Balance' },
                  { isactivecheck: '/dashboard/accounts', link: 'accounts', text: 'Agent Ledger' },
                ]} />
              <p className={'hidetext mb-2 mt-4 fontweight600 paragraphtext fontsize11 text-uppercase'}>Management</p>
              <Nav.Item className="mb-2">
                <Link to={'groups'} className={`nav-link ${isActive('/dashboard/groups') ? 'activenav' : ''}  justify-content-md-between justify-content-center`}><div className={'d-flex'}><p className="mb-0 space"><img className="img-fluid" src={grouphistoryicon} alt="icon" /></p><div className="hidetext ms-2">Groups </div></div><Badge bg={''} className={'hidetext'}>10+</Badge></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'airlines'} className={`nav-link ${isActive('/dashboard/airlines') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={airlinesicon} alt="icon" /></p><div className="hidetext ms-2">Airlines</div></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'sectors'} className={`nav-link ${isActive('/dashboard/sectors') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={sectorsicon1} alt="icon" /></p><div className="hidetext ms-2">Sectors</div></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'agents'} className={`nav-link ${isActive('/dashboard/agents') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={agentsicon} alt="icon" /></p><div className="hidetext ms-2">Agents</div></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'my-sectors'} className={`nav-link ${isActive('/dashboard/my-sectors') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={sectorsicon} alt="icon" /></p><div className="hidetext ms-2">My Sectors</div></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'emails'} className={`nav-link ${isActive('/dashboard/emails') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={sectorsicon} alt="icon" /></p><div className="hidetext ms-2">Emails</div></Link>
              </Nav.Item>
            </>}
          {/* Management */}
          <p className={'hidetext mb-2 mt-4 fontweight600 paragraphtext fontsize11 text-uppercase'}>Settings</p>
          {token.role === 'admin' ?
            <>
              <Nav.Item className="mb-2">
                <Link to={'my-profile'} className={`nav-link ${isActive('/dashboard/my-profile') ? 'activenav' : ''}  justify-content-md-between justify-content-center`}><div className={'d-flex'}><p className="mb-0 space"><img className="img-fluid" src={grouphistoryicon} alt="icon" /></p><div className="hidetext ms-2">Profile</div></div></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'users'} className={`nav-link ${isActive('/dashboard/users') ? 'activenav' : ''}  justify-content-md-between justify-content-center`}><div className={'d-flex'}><p className="mb-0 space"><img className="img-fluid" src={grouphistoryicon} alt="icon" /></p><div className="hidetext ms-2">All Users </div></div><Badge bg={''} className={'hidetext'}>10+</Badge></Link>
              </Nav.Item>
              {/* Apps */}
              <Nav.Item className="mb-2">
                <Link to={'groups-history'} className={`nav-link ${isActive('/dashboard/groups-history') ? 'activenav' : ''}  justify-content-md-between justify-content-center`}><div className={'d-flex'}><p className="mb-0 space"><img className="img-fluid" src={grouphistoryicon} alt="icon" /></p><div className="hidetext ms-2">Groups History</div></div><Badge bg={''} className={'hidetext'}>32+</Badge></Link>
              </Nav.Item>
              <Nav.Item className="mb-2">
                <Link to={'settings'} className={`nav-link ${isActive('/dashboard/settings') ? 'activenav' : ''} `}><p className="mb-0 space"><img className="img-fluid" src={settingicon} alt="icon" /></p><div className="hidetext ms-2">Settings</div></Link>
              </Nav.Item>
            </>
            :
            <>
              <Nav.Item className="mb-2">
                <Link to={'my-profile'} className={`nav-link ${isActive('/dashboard/my-profile') ? 'activenav' : ''}  justify-content-md-between justify-content-center`}><div className={'d-flex'}><p className="mb-0 space"><img className="img-fluid" src={grouphistoryicon} alt="icon" /></p><div className="hidetext ms-2">Profile</div></div></Link>
              </Nav.Item>
            </>}
        </div>
      </div>
    </>
  )
}

export default Sidebar;