import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Spinner, Table } from 'react-bootstrap';

// import images
import verticalicon from '../../../../images/verticalicon-dots.svg';
import { API_BASE_URL, formatDate, getBearerToken, setLoadingState } from '../../../../functions';
import axios from 'axios';
import { useAuth } from '../../auth/AuthProvider';

const AccountsTRLoop = ({
    data,
    isLoading,
    loadingKey,
    toastMessage,
    setToastMessage,
    showToast,
    setShowToast }) => {

    const [show, setShow] = useState(false)
    const [showCon, setShowCon] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [targetBooking, setTargetBooking] = useState('')
    const { token } = useAuth()



    const handalPayment = (booking) => {
        setTargetBooking(booking)
        setShow(true)
    }
    const handalCancel = (e, id) => {
        setLoadingState(true, 'CANCEL_BOOKING');
        axios.get(`${API_BASE_URL}/cancelbooking/${e}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            setLoadingState(false, '');
            setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
            setTimeout(() => {
                window.location.reload();
            }, 2000);

        }).catch(error => {
            // Handle errors
            setToastMessage({ type: 'failde', message: error.response.data.message });
            setShowToast('show')
            setLoadingState(false, '');


        });

    };

    const handalEdit = (id) => {
        setShowEdit(true)
        setTargetBooking(id)
    }
    const handalShow = (booking) => {
        setShowCon(true);
        setTargetBooking(booking)
    }

    // console.log(data);
    return (
        <>
            <Table responsive="sm" className="mb-0 reports">
                <thead className="thead-dark">
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>PNR</th>
                        {/* <th>By</th> */}
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Balance</th>
                        {/* <th>Action</th> */}
                    </tr>
                </thead>
                {data?.report?.length <= 0 ? (
                    <tr>
                        <td colSpan={7} className="pt-3 w-100 text-center">
                            No Record found.
                        </td>
                    </tr>
                ) :
                    <tbody>
                        <tr >
                            <td colSpan={4} className='fontweight700'> </td>
                            <td colSpan={2} className='fontweight700 text-center'>Opening Balance :  {data.opening_balance}</td>
                        </tr>
                        {data.report?.map((val, index) => (
                            <tr key={val.id}>
                                <td style={{ maxWidth: "35px" }}>{formatDate(val.receive_date)}</td>
                                <td style={{ maxWidth: "150px" }}>
                                    {val.text}{' BID : '}{val.booking.id}{' , '}
                                    <Link to={`/dashboard/book/${val.booking.id}`} className="text-decoration-none bluetext fontweight600">
                                        view
                                    </Link>
                                </td>
                                <td>
                                    {token.role === 'admin' ?
                                        <Link to={`/dashboard/pnr/${val.pnr}`} className="text-decoration-none bluetext fontweight600">
                                            {val.booking.pnr}
                                        </Link>
                                        :
                                        <>{val.booking.pnr}</>
                                    }
                                </td>
                                {/* <td>
                                    {val.added_by.name ? `Added by ${val.added_by.name}` : ""}
                                    {val.edit_by ? `Edit by ${val.edit_by}` : ""}
                                </td> */}
                                {val.type === 'out' ?
                                    <>
                                        <td>{val.receive_amount}</td>
                                        <td>-</td>
                                    </> : val.type === 'discount' ?
                                        <>
                                            <td>{val.receive_amount}</td>
                                            <td>-</td>
                                        </> :
                                        <>
                                            <td>-</td>
                                            <td>{val.receive_amount}</td>
                                        </>}
                                <td>{val.balance}</td>
                                {/* <td>
                                <Dropdown className="" align="end" autoClose="outside">
                                    <Dropdown.Toggle className="p-0 m-0 " variant="" id="dropdown-autoclose-outside">
                                        <img className="img-fluid" src={verticalicon} alt="icon" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/dashboard/book/${val?.id}`} className="dropdown-item">
                                            View
                                        </Link>
                                        <Link to={`#`} className="dropdown-item" onClick={(e) => handalPayment(val)}>
                                            Add Payment
                                        </Link>
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalShow(val)}>
                                            {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                :
                                                'Confirm Booking'
                                            }
                                        </Link>
                                        <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(val.id)}>
                                            {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                                :
                                                'Cancel Booking'
                                            }
                                        </Link>
                                        <Link to={`/dashboard/pnr/${val.pnr}`} className="dropdown-item">
                                            Open full PNR
                                        </Link>
                                        <Link to={`#`} className="dropdown-item" onClick={(e) => handalEdit(val.id)}>
                                            Edit Booking
                                        </Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td> */}
                            </tr>
                        ))}
                        {/* <tr className='border-top rep-total'>
                            <td></td>
                            <td></td>
                            <td className='fontweight700'>Agent Total : </td>
                            <td className='fontweight700'>{data.total}</td>
                            <td className='fontweight700'>{data.receive}</td>
                            <td className='fontweight700'>{data.remaining}</td>
                        </tr> */}
                    </tbody>}
            </Table>
            {show &&
                <AddTicketPay editData={targetBooking} show={show} setShow={setShow} />
            }
            {showCon &&
                <ConfirmBooking show={showCon} setShow={setShowCon} path='booking-confirm' ConData={targetBooking} />
            }
            {showEdit &&
                <EditTicket id={targetBooking} show={showEdit} setShow={setShowEdit} />
            }
        </>
    )
}

export default AccountsTRLoop;