import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, countries, getBearerToken } from '../../functions';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import { useAuth } from '../pages/auth/AuthProvider';
import eye from "../../images/eye.svg";
import toast from 'react-hot-toast';


const ChangePassword = ({ show, setShow, path = '' }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { token } = useAuth()
    const [data, setData] = useState(
        {
            email: token.email,
            old_password: '',
            new_password: ''
        }
    );
    const [passwordType, setPasswordType] = useState(true);
    const [showChnage, sethowChnage] = useState(false)
    const { updateTokenVAlue } = useAuth();
    const Navigate = useNavigate();



    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)

        const formdata1 = new FormData();
        formdata1.append('old_password', data.old_password)
        formdata1.append('new_password', data.new_password)
        formdata1.append('email', data.email)

        axios.post(`${API_BASE_URL}/changeuserpassword`, formdata1, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // console.log("in response", response);
            // Handle the response data
            setLoading(false);
            toast.success(response.data.message);
            updateTokenVAlue('change_password', false)
            handleClose()
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(error => {
            // Handle errors
            setLoading(false); // Set loading state to false in case of error
            toast.error(error.response.data.message)
        });
    };

    const logout = (e) => {
        e.preventDefault()
        localStorage.removeItem('token');
        Navigate('/signin')

    }

    // console.log("token.agent_id");

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton={false} className="d-flex px-4 align-items-center">
                    <Modal.Title id="contained-modal-title-vcenter">Chnage Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <p>Welcome! You need to update your password to get started. Its for your security. Thanks</p>
                    <div className="rounded mt-2 p-3 RGBAcolor1bg ">
                        <Row>
                            <Form className={'AllForm'} onSubmit={handleSubmit}>
                                <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Old Password</Form.Label>
                                        <Form.Control
                                            required
                                            type={passwordType ? "password" : "text"}
                                            placeholder='Old Password'
                                            value={data.old_password}
                                            onChange={(e) => setData({ ...data, old_password: e.target.value })}
                                        />
                                        <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                                    </Form.Group>
                                </Col>
                                <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            required
                                            type={passwordType ? "password" : "text"}
                                            value={data.new_password}
                                            placeholder='New Password'
                                            onChange={(e) => setData({ ...data, new_password: e.target.value })}
                                        />
                                        <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" onClick={() => setPasswordType(!passwordType)} /></span>
                                    </Form.Group>
                                </Col>
                                <div className="d-flex">
                                    <Button variant={''} disabled={(data.new_password === '' || data.old_password === '')} className='btn greenbg text-white fontsize14 fontweight700 me-3' type='submit'>
                                        {loading ?
                                            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                            : 'Save'
                                        }</Button>
                                    <Button variant={''} className='' onClick={(e) => logout(e)}>Logout</Button>
                                </div>
                            </Form>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangePassword;