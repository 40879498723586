import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, FormLabel, Spinner } from "react-bootstrap";

// import logo_black from "";
import eye from "../../images/eye.svg";
import signBanner from "../../images/signBanner.svg";
import logo from "../../images/logo.png";
import gmail from "../../images/gmail.svg";
import fbicon from "../../images/fbicon.svg";
import twittericon from "../../images/twittericon.svg";
import { API_BASE_URL } from '../../functions';
import toast from 'react-hot-toast';

const ThankYou = () => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [registrationData, setRegistrationData] = useState({
        email: '',
        name: '',
        office_no: '',
        phone: '',
        office_address: '',
        agency_name: '',
    });
    const navigate = useNavigate()
    const message = 'Hello, i need urgent login.'; // Replace with your desired message

    // Encode the message for the URL
    const encodedMessage = encodeURIComponent(message);

    // Construct the WhatsApp URL
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+923204817601&text=${encodedMessage}`;


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await fetch(`${API_BASE_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registrationData),
            });
            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', JSON.stringify(data.success));
                navigate('/thank-you')
                setLoading(false)
            } else {
                setError('Invalid email or password');
                toast.error(error.response.data.message)
                setLoading(false)
            }
        } catch (error) {
            setError('Something went wrong. Please try again later.');
            toast.error(error.response.data.message)
            setLoading(false)
        }
    };
    // console.log(registrationData);
    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Link to={'/'} className={'text-start w-100 d-block d-xl-none px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
                        <div className="login100-form">
                            <Form className="validate-form w-100" action="" onSubmit={handleSubmit}>
                                <span className="login100-form-title">Thank you for registering</span>
                                <p>You are registered please wait for the verification call. After successful registeration you will receive email from teh admin with your login details.</p>
                                <p className='mainblue fontweight500 mt-4 mb-2'>If you need urgently you can call/email us.</p>
                                <p>Phone :
                                    <a href='tel:+923194817601'>+92-319-4817601</a>
                                </p>
                                <p>Whatsapp : <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">+92-320-4817601</a></p>
                                <p>Email : <a href={'mailto:groups@dhilluandsahi.com'} target="_blank" rel="noopener noreferrer">groups@dhilluandsahi.com</a></p>
                                <div className="text-center">
                                    <Link to={'/'} className={'fontweight700 login100-form-btn fontsize14 whitetext btn w-100'}>Go to Home</Link>
                                    <p className="mt-4 text-center">Already have an account?<Link to={'/signin'} className="fontweight600 greentext fontsize14"> Log In</Link></p>

                                </div>
                            </Form>
                        </div>
                        <div className="login100-more">
                            <Link to={'/'} className={'text-start w-100 d-block px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
                            <div className={'h-100 d-flex align-items-center justify-content-center'} style={{ gap: '80px' }}>
                                <div>
                                    <h5 className={'mb-lg-5 loginTitle'}>Thank you for registering</h5>
                                    <div className="trevelAgency-logo text-center"><img src={signBanner} className={'img-fluid'} alt={'icon'} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYou;