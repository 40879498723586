import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Button, Card, Col, Dropdown, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';



// import component
import arrowicon from '../../../images/arrowicon.svg';
import verticalicon from '../../../images/verticalicon.svg';
import { API_BASE_URL, formatDateAndTime, getBearerToken, getLoadingState, setLoadingState } from '../../../functions';
import ToastMessage from '../../ToastMessage';
import { useAuth } from '../auth/AuthProvider';

const Notifications = () => {
  const [notifications, setNotifications] = useState('')
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth()



  useEffect(() => {
    setLoading(true)
    axios.get(`${API_BASE_URL}/listofnotification`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setNotifications(response?.data?.data)
      setLoading(false)
    }).catch(error => {
      // Handle errors
      console.error(error);
      setLoading(false)

    });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        {/* <h4>Agent Profile</h4> */}
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      <Row className={'m-0 p-0'}>
        <Col className={'mb-4 mb-lg-0 mx-auto'} xxl={'8'} xl={'8'} lg={'8'}>
          <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
            <Card.Header className="px-3 px-lg-4 py-3 text-center shadow-sm mb-3" style={{ background: 'transparent', border: 'none' }}>
              <h5 className="fontsize18 blacktext fontweight600">All Notifications</h5>
            </Card.Header>
            <Card.Body className="px-3 py-0 mb-3">
              {loading ?
                <div className="text-center">
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                </div>
                :
                <>
                  {notifications?.length > 0 ? notifications.map((val, index) => (
                    <Link to={`/dashboard/book/${val.src}`} className='notilist d-flex align-items-center justify-content-between p-2 '>
                      <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                        <div className={'AllPhoto'}><img className="img-fluid" src={val.image} alt="icon" /></div>
                        <div className={'ms-3 hidetext'}>
                          <h4 className={'name fontsize14 fontweight600 blacktext'}>{val.name}</h4>
                          <p className={'m-0 fontsize14 paragraphtext'}>{val.message}</p>
                        </div>
                      </div>
                      <div className=''>
                        {/* <Button variant={''} className='btn greenbg text-white fontsize14 fontweight700'>Edit</Button> */}
                        <span className='paragraphtext fontsize122 fontweight400'>{formatDateAndTime(val.created_at)}</span>
                      </div>
                    </Link>
                  ))
                    :
                    <div className="text-center">
                      <p className="m-0 px-0 py-3">No Notification found</p>
                    </div>
                  }
                </>
              }
            </Card.Body>
            {/* <Card.Footer className="px-3 px-lg-4 py-3 text-end" style={{ background: 'transparent' }}>
              <Link to="#" className="fontsize14 blacktext fontweight600 text-decoration-none">
                View All <img className="img-fluid" src={arrowicon} alt="icon" />
              </Link>
            </Card.Footer> */}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Notifications;