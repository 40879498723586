import React from 'react';
import {Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TopBoxes = (props) => {
  // console.log(props);
  return (
    <>
        <Col className={'mb-4'} xxl={'3'} xl={'3'} lg={'6'} md={'6'}>
        <Link to={`booking/${props.id}/${props.title}`} className='text-decoration-none'>
          <Card className={'card-body ' + props.boxcolor}>
            <div className={'mx-auto photo mb-3 h-100 ' + props.bgcolor}><img className={'img-fluid h-100 object-cover w-100 '} src={props.photoicon} alt={'icon'}/></div>
            <h4 className={'title text-decoration-none ' + props.colordiffrent}>{props.title}</h4>
            <small className={'fontweight500 ' + props.colormini}>{props.minitext} PNRs</small>
          </Card>
          </Link>
        </Col>
    </>
  )
}

export default TopBoxes;