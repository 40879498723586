import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthHOC = ({ Component }) => {

  const Navigate = useNavigate()
  const [loginCkeck, setLoginCheck] = useState(false)
  // console.log("AuthHOC AuthHOC AuthHOC AuthHOC AuthHOC");

  useEffect(() => {
    // If user is not authenticated, redirect to specified route
    // console.log("useEffect useEffect useEffect useEffect useEffect");
    if (localStorage?.getItem('token')) {
      // console.log("check login");
      setLoginCheck(true)
    } else {
      // console.log("Navigate signin");
      Navigate('/signin');
      return;
    }
  }, [Component])

  // Otherwise, render the element as usual
  return <div>
    {loginCkeck && <Component />}
  </div>;
};

export default AuthHOC;
