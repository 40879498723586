import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Dropdown, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL, getBearerToken, getLoadingState, setLoadingState } from '../../../functions';
import ToastMessage from '../../ToastMessage';


// import images
import verticalicon from '../.././../images/verticalicon.svg';
import AddTicketPay from '../../Modals/AddTicketPay';
import { useAuth } from '../auth/AuthProvider';
import ConfirmBooking from '../../Modals/ConfirmBooking';
import ConfirmationPopup from '../../Modals/ConfirmationPopup';
import toast from 'react-hot-toast';

const PnrTRLoop = (props) => {
    const [showToast, setShowToast] = useState('show')
    const [toastMessage, setToastMessage] = useState(null);
    const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key
    const [show, setShow] = useState(false)
    const [targetBooking, setTargetBooking] = useState('')
    const { token } = useAuth()
    const [showCon, setShowCon] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)



    // console.log('editData', props.editData);


    const handalCancel = (e, id) => {
        setShowConfirm(true)
        setTargetBooking(e)
    };

    const handleConfirm = (e) => {
        e.preventDefault()
        setShowConfirm(false)
        setLoadingState(true, 'CANCEL_BOOKING');
        const tid = toast.loading("Please wait...");
        axios.get(`${API_BASE_URL}/cancelbooking/${targetBooking}`, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        }).then(response => {
            // Handle the response data
            toast.success('Ticket cancelled successfully!');
            toast.dismiss(tid);
            setLoadingState(false, '');
            // setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
            setTimeout(() => {
                window.location.reload();
            }, 2000);

        }).catch(error => {
            // Handle errors
            // setToastMessage({ type: 'failde', message: error.response.data.message });
            toast.error(error.response.data.message, {
                id: tid,
            });
            setShowToast('show')
            setLoadingState(false, '');


        });
    }

    const handalPayment = (booking) => {
        setTargetBooking(booking)
        setShow(true)
    }

    const handalShow = (booking) => {
        setShowCon(true);
        setTargetBooking(booking)
    }

    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
            <tr>
                <td>
                    <Link to={'#'} className={'d-flex align-items-center text-decoration-none'}>
                        <div className='AllPhoto'><img className={'img-fluid'} src={props.usericon} alt={'icon'} /></div>
                        <h5 className={'ms-3 fontsize14 fontweight600 blacktext'}>{props.userTitle}</h5>
                    </Link>
                </td>
                <td>{props.sectorName}</td>
                <td>{props.shortName}</td>
                <td><Badge bg={''} className={`p-2 py-1 ${props.paymentStatus == 2 ? 'successcolorbg whitetext' : props.paymentStatus == 1 ? 'warninglighterbg yellowtext' : props.paymentStatus == 0 && 'errorlighterbg redtext'}`}>{props.paymentStatus == 2 ? 'Paid' : props.paymentStatus == 1 ? 'Partial' : props.paymentStatus == 0 && 'unpaid'}</Badge></td>
                <td>{props.agentName || 'Walking'}</td>
                <td>
                    <Badge
                        bg=""
                        className={`p-2 py-1 ${(props.confirmed == '1') ? 'successcolorbg whitetext' : (props.confirmed == '0') ? 'warninglighterbg yellowtext' : 'errorlighterbg redtext'}`}
                    >
                        {props.confirmed == '1' ? 'Confirmed' : props.confirmed == '0' ? 'Hold' : 'Cancelled'}
                    </Badge>
                </td>
                <td>
                    <Dropdown className={''} align={'end'} autoClose="outside">
                        <Dropdown.Toggle className={''} variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={verticalicon} alt={'icon'} /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Link to={`/dashboard/book/${props.id}`} className={'dropdown-item'}>View</Link>
                            {(token.role === 'admin' && props?.cancel_status !== '1') && <>
                                <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(props.id)}>
                                    {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                        :
                                        'Cancel Booking'
                                    }
                                </Link>
                                {props.confirmed == '1' ? <Link to={`#`} className="dropdown-item" onClick={(e) => handalPayment(props.editData)}>
                                    Add Payment
                                </Link> : null}
                                <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalShow(props.editData)}>
                                    {isLoading && loadingKey === 'CONFIRM_BOOKING' ?
                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                        :
                                        'Confirm Booking'
                                    }
                                </Link>
                            </>}

                        </Dropdown.Menu>
                    </Dropdown>
                </td>
            </tr>
            {show &&
                <AddTicketPay editData={targetBooking} show={show} setShow={setShow} />
            }
            {showCon &&
                <ConfirmBooking show={showCon} setShow={setShowCon} path='booking-confirm' ConData={targetBooking} />
            }
            {showConfirm &&
                <ConfirmationPopup show={showConfirm} setShow={setShowConfirm} handleConfirm={handleConfirm} />
            }
        </>
    )
}

export default PnrTRLoop;