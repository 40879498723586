import React from 'react'
import { Modal } from 'react-bootstrap'
import { formatDate } from '../../functions';

function UserProfile({ show, setShow, data, size }) {
    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} size={'lg'} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Modal.Header closeButton className="d-flex px-4 align-items-center">
                    {/* <Modal.Title id="contained-modal-title-vcenter">{`${data.name} Profile`}</Modal.Title> */}
                </Modal.Header>
                <Modal.Body className="px-4 user-profile">
                    <div className='mx-auto bgwhite rounded user-box'>
                        <div className='user-head'>
                            <div className='text-center'>
                                <div className='image'>
                                    <img className={'img-fluid h-100 object-cover'} src={data.image} alt={'icon'} />
                                </div>
                                <h5 className='fontsize18 fontweight700 m-0 mt-2 p-0'>{data.name}</h5>
                                <p className='fontsize12 fontweight500 m-0 p-0'>Agent ID : {data.id}</p>
                            </div>
                        </div>
                        <div className=''>
                            <p><b>Propriter :</b> {data.name}</p>
                            <p><b>Email :</b> {data.email}</p>
                            <p><b>Agency Name :</b> {data.agency_name}</p>
                            <p><b>Phone No. :</b> {data.phone}</p>
                            <p><b>Office No. :</b> {data.office_no}</p>
                            <p><b>Address :</b> {data.office_address}</p>
                            <p><b>City :</b> {data.city}</p>
                            <p><b>Country :</b> {data.country}</p>
                            <p><b>Join Date :</b> {formatDate(data.created_at)}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserProfile
