import React from 'react';
import {Link} from 'react-router-dom';
import { NavItem, Badge } from 'react-bootstrap';

const EndingDays = (props) => {
    // console.log(props);
  return (
    <>
        <Link to={`/dashboard/pnr/${props?.pnr}`} className={'d-flex align-items-start nav-item'}>
            <div className={'d-flex align-items-center'}>
                {/* <div className={'AllPhoto'} style={{borderRadius:'8px'}}><img className="img-fluid" src={props.avatarpic} alt="icon" /></div> */}
                <div className={'ms-3'}>
                    <Link to={`/dashboard/pnr/${props?.pnr}`} className={'text-decoration-none mb-1 d-block blacktext'}><h6 className={'blacktext fontsize14 fontweight700'}>{props.pnr || 'N/A'}</h6></Link>
                    <p className={'fontsize14 paragraphtext mb-0 fontweight500'}>{props.d_sector} <Badge className={'RGBAcolor3bg greentext py-2 ms-2'} bg={'success'}>{props.date}</Badge></p>
                </div>
            </div>
            <div className={'text-center ms-auto'}>
                <p className={'mb-0 fontsize14 fontweight500 blacktext'}>S : {props.sold}</p>
                <p className={'mb-0 fontsize12 paragraphtext'}>R : {props.rem}</p>
                <p className={'mb-0 fontsize12 paragraphtext'}>T : {props.total}</p>
            </div>
        </Link>
    </>
  )
}

export default EndingDays;