import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken, handleMouseWheel, toCapitalCase } from '../../../functions';
import { useParams } from 'react-router-dom';
import ToastMessage from '../../ToastMessage';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';

const EditGroup = () => {
  const [selectValue, setSelectValue] = useState("");
  const [toastMessage, setToastMessage] = useState(null);
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const Navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState({
    ticket_type: 1,
    direction: 1,
    airline: '',
    my_section_id: '',
    departure_sector: [],
    departure_baggage: [],
    departure_airport_name: [],
    departure_time: [],
    departure_date: [],
    departure_terminal: [],
    arivval_airport_name: [],
    arivval_time: [],
    arivval_date: [],
    arivval_terminal: [],
    // arivval_baggage: [],
    departure_flight_number: [],
    pnr: '',
    total_ticket: null,
    tickets_wehave: null,
    price: '',
    meal: '1'
    // added_by: '',
  });
  function transformApiData(apiResponse) {

    const transformedData = {
      airline: apiResponse.airline,
      arivval_airport_name: apiResponse.subpart?.map(subpart => subpart.arivval_airport_name),
      arivval_baggage: apiResponse.subpart?.map(subpart => subpart.arivval_baggage),
      arivval_date: apiResponse.subpart?.map(subpart => subpart.arivval_date),
      arivval_terminal: apiResponse.subpart?.map(subpart => subpart.arivval_terminal),
      arivval_time: apiResponse.subpart?.map(subpart => subpart.arivval_time),
      departure_airport_name: apiResponse.subpart?.map(subpart => subpart.departure_airport_name),
      departure_baggage: apiResponse.subpart?.map((subpart, i) => subpart.departure_baggage),
      departure_date: apiResponse.subpart?.map(subpart => subpart.departure_date),
      departure_flight_number: apiResponse.subpart?.map(subpart => subpart.departure_flight_number),
      departure_sector: apiResponse.subpart?.map(subpart => subpart.departure_sector),
      departure_terminal: apiResponse.subpart?.map(subpart => subpart.departure_terminal),
      departure_time: apiResponse.subpart?.map(subpart => subpart.departure_time),
      direction: apiResponse.direction,
      meal: apiResponse.meal,
      my_section_id: apiResponse.my_section_id,
      pnr: apiResponse.pnr,
      price: apiResponse.price,
      ticket_type: apiResponse.ticket_type,
      total_ticket: apiResponse.total_ticket,
      tickets_wehave: apiResponse.tickets_wehave,
      group_id: apiResponse.id,
    };
    return transformedData;
  }

  useEffect(() => {
    setEditMode(true)
    // Fetch data for editing only if editMode is true.
    if (id) {
      // Perform the data fetching logic for editing here.
      // For example:
      axios.get(`${API_BASE_URL}/editgroup/${id}`, {
        headers: {
          'Authorization': `Bearer ${getBearerToken()}`
        }
      }).then((response) => {
        setData(transformApiData(response?.data?.data)); // Set the data in the state for editing.
      })
        .catch((error) => {
          // Handle errors if needed.
        });
    }
  }, [id]);


  useEffect(() => {
    axios.get(`${API_BASE_URL}/general`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setSelectValue((response?.data?.data))
    }).catch(error => {
      // Handle errors
      console.error(error);
    });
  }, [])

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    const fromdata = new FormData();
    const apiEndpoint = editMode ? `update-group` : 'add-group';
    // console.log(apiEndpoint);
    fromdata.append('group_id', data.group_id)
    fromdata.append('ticket_type', data.ticket_type)
    fromdata.append('direction', data.direction)
    fromdata.append('airline', data.airline)
    fromdata.append('my_section_id', data.my_section_id)
    fromdata.append('pnr', data.pnr)
    fromdata.append('price', data.price)
    fromdata.append('meal', data.meal)
    fromdata.append('total_ticket', data.total_ticket)
    fromdata.append('tickets_wehave', data.tickets_wehave)
    Array.from(data.departure_sector).forEach((item) => {
      fromdata.append('departure_sector[]', item)
    });
    Array.from(data.departure_flight_number).forEach((item) => {
      fromdata.append('departure_flight_number[]', item)
    })
    Array.from(data.departure_baggage).forEach((item) => {
      fromdata.append('departure_baggage[]', item)
    })
    Array.from(data.departure_airport_name).forEach((item) => {
      fromdata.append('departure_airport_name[]', toCapitalCase(item))
    })
    Array.from(data.departure_time).forEach((item) => {
      fromdata.append('departure_time[]', item)
    })
    Array.from(data.departure_date).forEach((item) => {
      fromdata.append('departure_date[]', item)
    })
    Array.from(data.departure_terminal).forEach((item) => {
      fromdata.append('departure_terminal[]', toCapitalCase(item))
    })
    Array.from(data.arivval_airport_name).forEach((item) => {
      fromdata.append('arivval_airport_name[]', toCapitalCase(item))
    })
    Array.from(data.arivval_time).forEach((item) => {
      fromdata.append('arivval_time[]', item)
    })
    Array.from(data.arivval_date).forEach((item) => {
      fromdata.append('arivval_date[]', item)
    })
    Array.from(data.arivval_terminal).forEach((item) => {
      fromdata.append('arivval_terminal[]', toCapitalCase(item))
    })
    Array.from(data.departure_baggage).forEach((item) => {
      fromdata.append('arivval_baggage[]', item)
    })
    fromdata.append('added_by', data.added_by)
    // Call API to submit data
    axios.post(`${API_BASE_URL}/update-group`, fromdata, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    })
      // .then(response => response)
      .then(result => {
        // Handle response from API
        toast.success(result.data.message)
        setLoading(false)
        Navigate('/dashboard/groups');
      })
      .catch(error => {
        // Handle error
        toast.error(error.response.data.message)
        setLoading(false)
      });
  };

  // useEffect(() => {
  //   setData({
  //     ...data,
  //     airline: '',
  //     my_section_id: '',
  //     departure_sector: [],
  //     departure_baggage: [],
  //     departure_airport_name: [],
  //     departure_time: [],
  //     departure_date: [],
  //     departure_terminal: [],
  //     arivval_airport_name: [],
  //     arivval_time: [],
  //     arivval_date: [],
  //     departure_flight_number: [],
  //     arivval_terminal: [],
  //     pnr: '',
  //     total_ticket: null,
  //   });
  // }, [data.ticket_type, data.direction])

  const handleSelectChange = (event, field, index) => {
    setData(prevData => ({
      ...prevData,
      [field]: Object.assign([], prevData[field], { [index]: event.target.value })
    }));
  };

  function getValueAtIndex(fieldName, index) {
    // console.log(data);
    if (fieldName in data && Array.isArray(data[fieldName])) {
      const valuesArray = data[fieldName];
      if (index >= 0 && index < valuesArray.length) {
        return valuesArray[index];
      }
    }
    return null; // Or any default value you want to return if the field or index is not valid
  }

  // Example usage:
  const departureFlightNumberAtIndex0 = getValueAtIndex('departure_flight_number', 0);
  // console.log('Edit =>', data); 
  // Output: 'fasd'
  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
      <div className={'main_heading mb-3'}><h4>{editMode ? 'Edit Group' : 'Add New Group'}</h4></div>
      {/*  */}
      {/* <div className={'main_heading mb-3'}><h4 className={''} style={{ fontSize: '16px', }}>Group 1</h4></div> */}
      <Form className={'AllForm'} method='post' onSubmit={handleSubmit}>
        <Card className={'mb-4'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px', }}>
          <Card.Body className={'py-4'}>
            <Row className={''}>
              <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                  <Form.Label>Select ticket type</Form.Label>
                  <Form.Select aria-label="Default select example" disabled={true} value={data.ticket_type} onChange={(e) => setData({ ...data, ticket_type: e.target.value })} required>
                    <option value={1}>One Way</option>
                    <option value={2}>Round Trip</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                  <Form.Label>Select direction</Form.Label>
                  <Form.Select aria-label="Default select example" disabled={true} value={data.direction} onChange={(e) => setData({ ...data, direction: e.target.value })} required>
                    <option value={1}>Direct</option>
                    <option value={2}>Via</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className={'mb-4'} xl={'3'} lg={'3'}>
                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                  <Form.Label>Fare (Per Ticket Rate)</Form.Label>
                  <Form.Control type={'number'} placeholder="000000" aria-label="Username" aria-describedby="basic-addon1" value={data.price}
                    onChange={(e) => setData({ ...data, price: e.target.value })}
                    onWheel={handleMouseWheel} />
                </Form.Group>
              </Col>
              <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                  <Form.Label>Meal</Form.Label>
                  <Form.Select aria-label="Default select example" value={data.meal} onChange={(e) => setData({ ...data, meal: e.target.value })} required>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/*  */}
        {!selectValue ?
          <div className={'mt-3 w-100 text-center'}>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
          </div>
          : data.ticket_type == 1 && data.direction == 1 ?
            //One Way + Direct

            <Card className={'mb-4'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px', }}>
              <Card.Body>
                <Row className={' mb-4'}>
                  <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Airline</Form.Label>
                      <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, airline: e.target.value })} value={data.airline} required>
                        <option className='whitetext' value={''}>select value here</option>
                        {selectValue?.airline?.map((item, i) => {
                          return (<option value={item?.id} key={item.id}>{item?.full_name}</option>)
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>My Sector</Form.Label>
                      <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, my_section_id: e.target.value })} value={data.my_section_id} required>
                        <option className='whitetext' value={''}>select value here</option>
                        {selectValue?.my_sectors?.map((item, i) => {
                          return (<option value={item?.id} key={item.id}>{item?.name}</option>)
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                      <Form.Label>Flight Number</Form.Label>
                      <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_flight_number", 0)}
                        value={getValueAtIndex('departure_flight_number', 0)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/*  */}
                <Row className={'mb-4'}>
                  <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>PNR</Form.Label>
                      <Form.Control type={'text'} placeholder="PNR" required aria-label="Username" aria-describedby="basic-addon1" value={data.pnr}
                        onChange={(e) => setData({ ...data, pnr: e.target.value })} />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Sector</Form.Label>
                      <Form.Select aria-label="Default select example" required
                        onChange={(event) => handleSelectChange(event, "departure_sector", 0)}
                        value={getValueAtIndex('departure_sector', 0)}
                      >
                        <option className='whitetext' value={''}>select value here</option>
                        {selectValue?.sectors?.map((item, i) => {
                          return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Tickets We Have</Form.Label>
                      <Form.Control type={'number'} placeholder="20" required aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(e) => setData({ ...data, tickets_wehave: e.target.value })}
                        value={data.tickets_wehave}
                        onWheel={handleMouseWheel}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'4'} lg={'4'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Total Tickets</Form.Label>
                      <Form.Control type={'number'} placeholder="12" required aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(e) => setData({ ...data, total_ticket: e.target.value })}
                        value={data.total_ticket}
                        onWheel={handleMouseWheel}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4 mb-lg-0'} xl={'12'} lg={'12'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Baggage</Form.Label>
                      <Form.Control type={'number'} placeholder="only numbers without kg,lb etc." required aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_baggage", 0)}
                        value={getValueAtIndex('departure_baggage', 0)}
                        onWheel={handleMouseWheel}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/*  */}
                <Row className={''}>
                  <small className={'d-block mb-3'}>Departure</small>
                  <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Departure Date</Form.Label>
                      <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_date", 0)}
                        value={getValueAtIndex('departure_date', 0)}

                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Air port name</Form.Label>
                      <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_airport_name", 0)}
                        value={getValueAtIndex('departure_airport_name', 0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Time</Form.Label>
                      <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_time", 0)}
                        value={getValueAtIndex('departure_time', 0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Terminal (optional)</Form.Label>
                      <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "departure_terminal", 0)}
                        value={getValueAtIndex('departure_terminal', 0)}

                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/*  */}
                <Row className={''}>
                  <small className={'d-block mb-3'}>Arivval</small>
                  <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Arrival Date</Form.Label>
                      <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "arivval_date", 0)}
                        value={getValueAtIndex('arivval_date', 0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Air port name</Form.Label>
                      <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "arivval_airport_name", 0)}
                        value={getValueAtIndex('arivval_airport_name', 0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>time</Form.Label>
                      <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "arivval_time", 0)}
                        value={getValueAtIndex('arivval_time', 0)}
                      />
                    </Form.Group>
                  </Col>
                  <Col className={'mb-4'} xl={'3'} lg={'3'}>
                    <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                      <Form.Label>Terminal (optional)</Form.Label>
                      <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                        onChange={(event) => handleSelectChange(event, "arivval_terminal", 0)}
                        value={getValueAtIndex('arivval_terminal', 0)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              {/*  */}
              <Card.Footer className="d-flex align-items-center py-4" style={{ background: 'transparent' }}>
                <div>
                  <small className={'d-block'}>Added By You</small>
                </div>
                <div className={'d-flex align-items-center ms-auto'}>
                  <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3">Cancel</Button>
                  <Button disabled={loading} variant="" className="w-100px text-white fontweight700 fontsize14 greenbg px-5" type='submit'>
                    {loading ?
                      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                      : 'Save'
                    }
                  </Button>
                </div>
              </Card.Footer>
            </Card>

            //One Way + Direct Ends
            : data.ticket_type == 1 && data.direction == 2 ?
              //One Way + Via

              <Card className={'mb-4'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px', }}>
                <Card.Body>
                  <Row className={''}>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Airline</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, airline: e.target.value })} value={data.airline} required>
                          <option className='whitetext' value={''}>select value here</option>
                          {selectValue?.airline?.map((item, i) => {
                            return (<option value={item?.id} key={item.id}>{item?.full_name}</option>)
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>My Sector</Form.Label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, my_section_id: e.target.value })} value={data.my_section_id} required>
                          <option className='whitetext' value={''}>select value here</option>
                          {selectValue?.my_sectors?.map((item, i) => {
                            return (<option value={item?.id} key={item.id}>{item?.name}</option>)
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>PNR</Form.Label>
                        <Form.Control type={'text'} placeholder="PNR" required aria-label="Username" aria-describedby="basic-addon1" value={data.pnr} onChange={(e) => setData({ ...data, pnr: e.target.value })} />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Tickets We Have</Form.Label>
                        <Form.Control type={'number'} placeholder="20" required aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(e) => setData({ ...data, tickets_wehave: e.target.value })}
                          value={data.tickets_wehave}
                          onWheel={handleMouseWheel}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Total Tickets</Form.Label>
                        <Form.Control type={'number'} placeholder="12" required aria-label="Username" aria-describedby="basic-addon1"
                          value={data.total_ticket}
                          onChange={(e) => setData({ ...data, total_ticket: e.target.value })}
                          onWheel={handleMouseWheel} />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/*  */}
                  <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                    <strong className={'d-block mb-3'}>Trip A-1</strong>
                    <Col className={'mb-4'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Sector</Form.Label>
                        <Form.Select aria-label="Default select example" required
                          onChange={(event) => handleSelectChange(event, "departure_sector", 0)}
                          value={getValueAtIndex('departure_sector', 0)}
                        >
                          <option className='whitetext' value={''}>select value here</option>
                          {selectValue?.sectors?.map((item, i) => {
                            return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                        <Form.Label>Flight Number</Form.Label>
                        <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_flight_number", 0)}
                          value={getValueAtIndex('departure_flight_number', 0)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Baggage</Form.Label>
                        <Form.Control type={'number'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_baggage", 0)}
                          value={getValueAtIndex('departure_baggage', 0)}
                          onWheel={handleMouseWheel}
                        />
                      </Form.Group>
                    </Col>
                    <small className={'d-block mb-3'}>Departure</small>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Departure Date</Form.Label>
                        <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_date", 0)}
                          value={getValueAtIndex('departure_date', 0)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Air port name</Form.Label>
                        <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_airport_name", 0)}
                          value={getValueAtIndex('departure_airport_name', 0)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Time</Form.Label>
                        <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_time", 0)}
                          value={getValueAtIndex('departure_time', 0)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Terminal (optional)</Form.Label>
                        <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_terminal", 0)}
                          value={getValueAtIndex('departure_terminal', 0)}
                        />
                      </Form.Group>
                    </Col>
                    <Row className={''}>
                      <small className={'d-block mb-3'}>Arivval</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Arrival Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_date", 0)}
                            value={getValueAtIndex('arivval_date', 0)}

                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_airport_name", 0)}
                            value={getValueAtIndex('arivval_airport_name', 0)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>time</Form.Label>
                          <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_time", 0)}
                            value={getValueAtIndex('arivval_time', 0)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_terminal", 0)}
                            value={getValueAtIndex('arivval_terminal', 0)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                    <strong className={'d-block mb-3'}>Trip A-2</strong>
                    <Col className={'mb-4'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Sector</Form.Label>
                        <Form.Select aria-label="Default select example" required
                          onChange={(event) => handleSelectChange(event, "departure_sector", 1)}
                          value={getValueAtIndex('departure_sector', 1)}
                        >
                          <option className='whitetext' value={''}>select value here</option>
                          {selectValue?.sectors?.map((item, i) => {
                            return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                        <Form.Label>Flight Number</Form.Label>
                        <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_flight_number", 1)}
                          value={getValueAtIndex('departure_flight_number', 1)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Baggage</Form.Label>
                        <Form.Control type={'number'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_baggage", 1)}
                          value={getValueAtIndex('departure_baggage', 1)}
                          onWheel={handleMouseWheel}
                        />
                      </Form.Group>
                    </Col>
                    <small className={'d-block mb-3'}>Departure</small>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Departure Date</Form.Label>
                        <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_date", 1)}
                          value={getValueAtIndex('departure_date', 1)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Air port name</Form.Label>
                        <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_airport_name", 1)}
                          value={getValueAtIndex('departure_airport_name', 1)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Time</Form.Label>
                        <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_time", 1)}
                          value={getValueAtIndex('departure_time', 1)}
                        />
                      </Form.Group>
                    </Col>
                    <Col className={'mb-4'} xl={'3'} lg={'3'}>
                      <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                        <Form.Label>Terminal (optional)</Form.Label>
                        <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                          onChange={(event) => handleSelectChange(event, "departure_terminal", 1)}
                          value={getValueAtIndex('departure_terminal', 1)}
                        />
                      </Form.Group>
                    </Col>
                    <Row className={''}>
                      <small className={'d-block mb-3'}>Arivval</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Arrival Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_date", 1)}
                            value={getValueAtIndex('arivval_date', 1)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_airport_name", 1)}
                            value={getValueAtIndex('arivval_airport_name', 1)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>time</Form.Label>
                          <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_time", 1)}
                            value={getValueAtIndex('arivval_time', 1)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "arivval_terminal", 1)}
                            value={getValueAtIndex('arivval_terminal', 1)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Row>
                  {/*  */}

                </Card.Body>
                {/*  */}
                <Card.Footer className="d-flex align-items-center py-4" style={{ background: 'transparent' }}>
                  <div>
                    <small className={'d-block'}>Added By You</small>
                  </div>
                  <div className={'d-flex align-items-center ms-auto'}>
                    <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3">Cancel</Button>
                    <Button disabled={loading} variant="" className="w-100px text-white fontweight700 fontsize14 greenbg px-5" type='submit'>
                      {loading ?
                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                        : 'Save'
                      }
                    </Button>
                  </div>
                </Card.Footer>
              </Card>

              //One Way + Via Ends
              : data.ticket_type == 2 && data.direction == 1 ?
                //Round Trip + Direct


                <Card className={'mb-4'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px', }}>
                  <Card.Body>
                    <Row className={''}>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Airline</Form.Label>
                          <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, airline: e.target.value })} value={data.airline} required>
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.airline?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.full_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>My Sector</Form.Label>
                          <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, my_section_id: e.target.value })} value={data.my_section_id} required>
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.my_sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>PNR</Form.Label>
                          <Form.Control type={'text'} placeholder="PNR" required aria-label="Username" aria-describedby="basic-addon1" value={data.pnr} onChange={(e) => setData({ ...data, pnr: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Tickets We Have</Form.Label>
                          <Form.Control type={'number'} placeholder="20" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(e) => setData({ ...data, tickets_wehave: e.target.value })}
                            value={data.tickets_wehave}
                            onWheel={handleMouseWheel}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Total Tickets</Form.Label>
                          <Form.Control type={'number'} placeholder="12" required aria-label="Username" aria-describedby="basic-addon1"
                            value={data.total_ticket}
                            onChange={(e) => setData({ ...data, total_ticket: e.target.value })}
                            onWheel={handleMouseWheel} />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/*  */}
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip A-1</strong>
                      <Col className={'mb-4'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 0)}
                            value={getValueAtIndex('departure_sector', 0)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 0)}
                            value={getValueAtIndex('departure_flight_number', 0)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Baggage</Form.Label>
                          <Form.Control type={'number'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_baggage", 0)}
                            value={getValueAtIndex('departure_baggage', 0)}
                            onWheel={handleMouseWheel} />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 0)}
                            value={getValueAtIndex('departure_date', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 0)}
                            value={getValueAtIndex('departure_airport_name', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 0)}
                            value={getValueAtIndex('departure_time', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 0)}
                            value={getValueAtIndex('departure_terminal', 0)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 0)}
                              value={getValueAtIndex('arivval_date', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 0)}
                              value={getValueAtIndex('arivval_airport_name', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 0)}
                              value={getValueAtIndex('arivval_time', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 0)}
                              value={getValueAtIndex('arivval_terminal', 0)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip B-1</strong>
                      <Col className={'mb-4'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 1)}
                            value={getValueAtIndex('departure_sector', 1)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 1)}
                            value={getValueAtIndex('departure_flight_number', 1)}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Baggage</Form.Label>
                          <Form.Control ttype={'number'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_baggage", 1)}
                            value={getValueAtIndex('departure_baggage', 1)}
                            onWheel={handleMouseWheel} />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 1)}
                            value={getValueAtIndex('departure_date', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 1)}
                            value={getValueAtIndex('departure_airport_name', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 1)}
                            value={getValueAtIndex('departure_time', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 1)}
                            value={getValueAtIndex('departure_terminal', 1)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 1)}
                              value={getValueAtIndex('arivval_date', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 1)}
                              value={getValueAtIndex('arivval_airport_name', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 1)}
                              value={getValueAtIndex('arivval_time', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 1)}
                              value={getValueAtIndex('arivval_terminal', 1)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    {/*  */}

                  </Card.Body>
                  {/*  */}
                  <Card.Footer className="d-flex align-items-center py-4" style={{ background: 'transparent' }}>
                    <div>
                      <small className={'d-block'}>Added By You</small>
                    </div>
                    <div className={'d-flex align-items-center ms-auto'}>
                      <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3">Cancel</Button>
                      <Button disabled={loading} variant="" className="w-100px text-white fontweight700 fontsize14 greenbg px-5" type='submit'>
                        {loading ?
                          <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                          : 'Save'
                        }
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>

                //Round Trip + Direct Ends
                : data.ticket_type == 2 && data.direction == 2 &&
                //Round Trip + Via

                <Card className={'mb-4'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px', }}>
                  <Card.Body>
                    <Row className={''}>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Airline</Form.Label>
                          <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, airline: e.target.value })} value={data.airline} required>
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.airline?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.full_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>My Sector</Form.Label>
                          <Form.Select aria-label="Default select example" onChange={(e) => setData({ ...data, my_section_id: e.target.value })} value={data.my_section_id} required>
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.my_sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>PNR</Form.Label>
                          <Form.Control type={'text'} placeholder="PNR" required aria-label="Username" aria-describedby="basic-addon1" value={data.pnr} onChange={(e) => setData({ ...data, pnr: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Tickets We Have</Form.Label>
                          <Form.Control type={'number'} placeholder="20" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(e) => setData({ ...data, tickets_wehave: e.target.value })}
                            value={data.tickets_wehave}
                            onWheel={handleMouseWheel}
                          />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Total Tickets</Form.Label>
                          <Form.Control type={'number'} placeholder="12" required aria-label="Username" aria-describedby="basic-addon1"
                            value={data.total_ticket}
                            onChange={(e) => setData({ ...data, total_ticket: e.target.value })}
                            onWheel={handleMouseWheel} />
                        </Form.Group>
                      </Col>
                    </Row>
                    {/*  */}
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip A-1</strong>
                      <Col className={'mb-4'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 0)}
                            value={getValueAtIndex('departure_sector', 0)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 0)}
                            value={getValueAtIndex('departure_flight_number', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Baggages</Form.Label>
                          <Form.Control type={'text'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => {
                              handleSelectChange(event, "departure_baggage", 0)
                              handleSelectChange(event, "departure_baggage", 1)
                            }}
                            value={getValueAtIndex('departure_baggage', 0)}
                          />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 0)}
                            value={getValueAtIndex('departure_date', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 0)}
                            value={getValueAtIndex('departure_airport_name', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 0)}
                            value={getValueAtIndex('departure_time', 0)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 0)}
                            value={getValueAtIndex('departure_terminal', 0)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 0)}
                              value={getValueAtIndex('arivval_date', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 0)}
                              value={getValueAtIndex('arivval_airport_name', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 0)}
                              value={getValueAtIndex('arivval_time', 0)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 0)}
                              value={getValueAtIndex('arivval_terminal', 0)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip A-2</strong>
                      <Col className={'mb-4'} xl={'6'} lg={'6'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 1)}
                            value={getValueAtIndex('departure_sector', 1)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'6'} lg={'6'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 1)}
                            value={getValueAtIndex('departure_flight_number', 1)} />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 1)}
                            value={getValueAtIndex('departure_date', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 1)}
                            value={getValueAtIndex('departure_airport_name', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 1)}
                            value={getValueAtIndex('departure_time', 1)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 1)}
                            value={getValueAtIndex('departure_terminal', 1)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 1)}
                              value={getValueAtIndex('arivval_date', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 1)}
                              value={getValueAtIndex('arivval_airport_name', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 1)}
                              value={getValueAtIndex('arivval_time', 1)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 1)}
                              value={getValueAtIndex('arivval_terminal', 1)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip B-1</strong>
                      <Col className={'mb-4'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 2)}
                            value={getValueAtIndex('departure_sector', 2)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 2)}
                            value={getValueAtIndex('departure_flight_number', 2)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Baggage</Form.Label>
                          <Form.Control type={'number'} placeholder="12kg" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => {
                              handleSelectChange(event, "departure_baggage", 2)
                              handleSelectChange(event, "departure_baggage", 3)
                            }}
                            value={getValueAtIndex('departure_baggage', 2)}
                            onWheel={handleMouseWheel}
                          />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 2)}
                            value={getValueAtIndex('departure_date', 2)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 2)}
                            value={getValueAtIndex('departure_airport_name', 2)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="time" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 2)}
                            value={getValueAtIndex('departure_time', 2)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 2)}
                            value={getValueAtIndex('departure_terminal', 2)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 2)}
                              value={getValueAtIndex('arivval_date', 2)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 2)}
                              value={getValueAtIndex('arivval_airport_name', 2)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 2)}
                              value={getValueAtIndex('arivval_time', 2)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 2)}
                              value={getValueAtIndex('arivval_terminal', 2)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    <Row className={'mb-3 m-0 p-3'} style={{ border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '16px' }}>
                      <strong className={'d-block mb-3'}>Trip B-2</strong>
                      <Col className={'mb-4'} xl={'6'} lg={'6'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Sector</Form.Label>
                          <Form.Select aria-label="Default select example" required
                            onChange={(event) => handleSelectChange(event, "departure_sector", 3)}
                            value={getValueAtIndex('departure_sector', 3)}
                          >
                            <option className='whitetext' value={''}>select value here</option>
                            {selectValue?.sectors?.map((item, i) => {
                              return (<option value={item?.id} key={item.id}>{item?.sector_name}</option>)
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'6'} lg={'6'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1" >
                          <Form.Label>Flight Number</Form.Label>
                          <Form.Control type={'text'} placeholder="23K6" required aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_flight_number", 3)}
                            value={getValueAtIndex('departure_flight_number', 3)} />
                        </Form.Group>
                      </Col>
                      <small className={'d-block mb-3'}>Departure</small>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Departure Date</Form.Label>
                          <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_date", 3)}
                            value={getValueAtIndex('departure_date', 3)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Air port name</Form.Label>
                          <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_airport_name", 3)}
                            value={getValueAtIndex('departure_airport_name', 3)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Time</Form.Label>
                          <Form.Control type={'time'} required placeholder="24kg" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_time", 3)}
                            value={getValueAtIndex('departure_time', 3)} />
                        </Form.Group>
                      </Col>
                      <Col className={'mb-4'} xl={'3'} lg={'3'}>
                        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                          <Form.Label>Terminal (optional)</Form.Label>
                          <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                            onChange={(event) => handleSelectChange(event, "departure_terminal", 3)}
                            value={getValueAtIndex('departure_terminal', 3)} />
                        </Form.Group>
                      </Col>
                      <Row className={''}>
                        <small className={'d-block mb-3'}>Arivval</small>
                        <Col className={'mb-4 mb-lg-0'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Arrival Date</Form.Label>
                            <Form.Control type={'date'} required placeholder="Date" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_date", 3)}
                              value={getValueAtIndex('arivval_date', 3)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Air port name</Form.Label>
                            <Form.Control type={'text'} required placeholder="Air port name" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_airport_name", 3)}
                              value={getValueAtIndex('arivval_airport_name', 3)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Time</Form.Label>
                            <Form.Control type={'time'} required placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_time", 3)}
                              value={getValueAtIndex('arivval_time', 3)} />
                          </Form.Group>
                        </Col>
                        <Col className={'mb-4'} xl={'3'} lg={'3'}>
                          <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                            <Form.Label>Terminal (optional)</Form.Label>
                            <Form.Control type={'text'} placeholder="12" aria-label="Username" aria-describedby="basic-addon1"
                              onChange={(event) => handleSelectChange(event, "arivval_terminal", 3)}
                              value={getValueAtIndex('arivval_terminal', 3)} />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>
                    {/*  */}
                  </Card.Body>
                  {/*  */}
                  <Card.Footer className="d-flex align-items-center py-4" style={{ background: 'transparent' }}>
                    <div>
                      <small className={'d-block'}>Added By You</small>
                    </div>
                    <div className={'d-flex align-items-center ms-auto'}>
                      <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3">Cancel</Button>
                      <Button disabled={loading} variant="" className="w-100px text-white fontweight700 fontsize14 greenbg px-5" type='submit'>
                        {loading ?
                          <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                          : 'Save'
                        }
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>

          //Round Trip + Via Ends

        }
      </Form>
    </>
  )
}

export default EditGroup;