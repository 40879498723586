import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Button, Dropdown, InputGroup, Form } from 'react-bootstrap';

// import images
import logo from '../images/logowbg.png';
import searchicon from '../images/searchicon.svg';
import avatar from '../images/avatarpic3.jpg';
import toggle_baricon from "../images/toggle_baricon.svg";
import notificationicon from "../images/notifications.svg";
import { useAuth } from './pages/auth/AuthProvider';
import { pusher } from '../pusherConfig';

const Header = () => {
  const Navigate = useNavigate()
  const [searchQuery, setSearchQuesry] = useState()
  const { token } = useAuth()


  const logout = (e) => {
    e.preventDefault()
    pusher.unsubscribe(`userchannel-${token.user_id}`)
    localStorage.removeItem('token');
    Navigate('/signin')

  }
  const handalSearch = (e) => {
    Navigate(`search/${searchQuery}`)
  }


  return (
    <>
      <div className="header align-items-center d-flex">
        <Navbar className="w-100 h-100">
          <div className='logoWrapp  '>
            <div className={'sidebarCollapse me-2 d-md-none'}><Button variant={''} id="sidebarCollapse" type="button" className="h-100 w-100 p-0 text-center d-flex align-items-center justify-content-center"><img className="img-fluid" src={toggle_baricon} alt="icon" /></Button></div>
            <Link to={'/'} className={'d-block w-100'}><img className={'img-fluid'} src={logo} alt={'icon'} /></Link>
          </div>
          <div className={'d-flex align-items-center w-100 justify-content-between px-3'}>
            <div className='d-flex align-items-center'>
            <Form onSubmit={handalSearch} >
              <InputGroup className="mb-0 d-md-flex d-none">
                <Form.Control placeholder="Search by email , PNR" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setSearchQuesry(e.target.value)} />
                <Button variant={''} id="basic-addon1"><img className={'img-fluid'} src={searchicon} alt={'icon'} /></Button>
              </InputGroup>
            </Form>
            {/* search button on mobile */}
            <Button variant={''} className={'mb-0 d-md-none d-block'} id="basic-addon1"><img className={'img-fluid'} src={searchicon} alt={'icon'} /></Button>
            {/*  */}
            <div>
              <Link to={'/'} className='ms-3'>Visit website</Link>
            </div>
            </div>

            <div className="d-flex align-items-center">
              <div className='notifications me-2'>
                <Link className='btn' to={'notifications'}><span className='number'>0</span> <img className={'img-fluid'} src={notificationicon} alt={'icon'} /></Link>
              </div>
              <Dropdown className="profileLogin" autoClose="outside" align="end">
                <Dropdown.Toggle variant={''} id="dropdown-autoclose-outside"><img className={'img-fluid'} src={token.image || avatar} alt={'icon'} /></Dropdown.Toggle>
                <Dropdown.Menu>
                  <Link className={'dropdown-item fontweight700 blacktext text-capitalize'} to="/signin">{token.name}</Link>
                  <Link className={'dropdown-item fontsize14 fontweight400 blacktext text-capitalize'} to="#"> Agent ID : DNS-{token.agent_id}</Link>
                  <Dropdown.Divider />
                  <Link className={'dropdown-item'} to="#" onClick={(e) => logout(e)}>Logout</Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  )
}

export default Header;