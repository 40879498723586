import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button, FormLabel, Spinner } from "react-bootstrap";

// import logo_black from "";
import eye from "../../images/eye.svg";
import signBanner from "../../images/signBanner.svg";
import logo from "../../images/logo.png";
import gmail from "../../images/gmail.svg";
import fbicon from "../../images/fbicon.svg";
import twittericon from "../../images/twittericon.svg";
import { API_BASE_URL, countries } from '../../functions';
import toast from 'react-hot-toast';

const SingUp = () => {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [registrationData, setRegistrationData] = useState({
        email: '',
        name: '',
        office_no: '',
        phone: '',
        office_address: '',
        agency_name: '',
        city: '',
        country: 'Pakistan'
    });
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registrationData),
            });

            if (response.ok) {
                // Successful response
                const data = await response.json();
                // console.log(data); // Log the response data
                navigate('/thank-you');
            } else {
                // Handle error response
                const errorData = await response.json();
                setError(errorData.message || 'Something went wrong. Please try again later');
            }
        } catch (error) {
            // Handle network or other errors
            setError('Something went wrong. Please try again later.');
        } finally {
            setLoading(false);
        }
    };



    return (
        <>
            <div className="limiter">
                <div className="container-login100">
                    <div className="wrap-login100">
                        <Link to={'/'} className={'text-start w-100 d-block d-xl-none px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
                        <div className="login100-form">
                            <Form className="validate-form w-100" action="" onSubmit={handleSubmit}>
                                <span className="login100-form-title">Register to Dhillu & Sahi Travels</span>
                                {/* <p>New user? <Link to={'/signup'} className={'fontweight600 greentext fontsize14'}>Create an account</Link></p> */}
                                <Row className={'align-items-center mb-4 mt-5'}>
                                    <Col xl={6} className="mb-xl-0">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="First Name/Given Name">
                                                <FormLabel className="0">Propriter Name</FormLabel>
                                                <input className="input100" placeholder="name" type="text" autoComplete="on" id="name" name="name" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, name: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} className="mb-xl-0">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="Last Name/Surname">
                                                <FormLabel className="0">Agency Name</FormLabel>
                                                <input className="input100" placeholder="XYZ" type="text" autoComplete="on" id="" name="" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, agency_name: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col xl={12} className="mb-4">
                                    <Form.Group>
                                        <div className="wrap-input100 validate-input" data-validate="Email Address">
                                            <FormLabel className="0">Email Address</FormLabel>
                                            <input className="input100" placeholder="demo@fasdf.com" type="email" autoComplete="on" id="email" name="email" required
                                                onChange={(e) => setRegistrationData({ ...registrationData, email: e.target.value })}
                                            />
                                            <span className="focus-input100"></span>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Row className={'align-items-center mb-4'}>
                                    <Col xl={6} className="">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="Personal Number">
                                                <FormLabel className="0" for='phone'>Personal Number</FormLabel>
                                                <input className="input100" placeholder="E.g : 0312-34567890" type="tel" id="phone" name="phone" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, phone: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} className="">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="Office Number">
                                                <FormLabel className="0" for='phone1'>Office Number</FormLabel>
                                                <input className="input100" placeholder="E.g : 0312-34567890" type="tel" id="phone1" name="phone" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, office_no: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className={'align-items-center mb-4'}>
                                    <Col xl={12} className="">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="Email Address">
                                                <FormLabel className="0">Office Address</FormLabel>
                                                <input className="input100" placeholder="shop #, Road etc." type="address" autoComplete="on" id="Address" name="Address" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, office_address: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} className="mt-4">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="Email Address">
                                                <FormLabel className="0">City</FormLabel>
                                                <input className="input100" placeholder="City" type="address" autoComplete="on" id="Address" name="Address" required
                                                    onChange={(e) => setRegistrationData({ ...registrationData, city: e.target.value })}
                                                />
                                                <span className="focus-input100"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={6} className="mt-4">
                                        <Form.Group>
                                            <div className="wrap-input100 validate-input" data-validate="country">
                                                <FormLabel className="0">Country</FormLabel>
                                                <select className="input100 h-100" value={registrationData.country} onChange={(e) => setRegistrationData({ ...registrationData, country: e.target.value })}>
                                                    <option value="">Select</option>
                                                    {countries.map((country, index) => (
                                                        <option key={index} value={country}>
                                                            {country}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Col xl={12} className="mb-3">
                                    <Form.Group>
                                        <div className="wrap-input100 validate-input" data-validate="Password is required">
                                            <input className="input100" type="password" id="password" placeholder="Password" name="password" />
                                            <span className="focus-input100"></span>
                                            <span className="focus-input100-img"><img className="img-fluid" src={eye} alt="eye" /></span>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="contact100-form-checkbox">
                                        <Form.Group className="mb-0 d-flex align-items-center">
                                            <Form.Check />
                                            <FormLabel label="inputPassword5" className="m-0 ms-3">Stay Log In</FormLabel>
                                        </Form.Group>
                                    </div>
                                    <p className="ms-auto"><Link to={'/forgot'} className="txt1">Forgot password?</Link></p>
                                </div> */}
                                {error && <div className="redtext mb-2">{error}</div>}
                                <Button variant="" type="submit" id="submit" className="login100-form-btn" disabled={loading}>{!loading ? 'Register' : <Spinner as='span' animation='border' size='md' role='status' aria-hidden='true' />}</Button>
                                <p className="mt-4 text-center">Already have an account?<Link to={'/signin'} className="fontweight600 greentext fontsize14"> Log In</Link></p>
                                {/* <div className={'d-flex align-items-center justify-content-center'}>
                                    <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={gmail} alt={'icon'} /></Link>
                                    <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={fbicon} alt={'icon'} /></Link>
                                    <Link to="#" className={'d-flex align-items-center justify-content-center px-2'}><img className={'img-fluid'} src={twittericon} alt={'icon'} /></Link>
                                </div> */}
                            </Form>
                        </div>
                        <div className="login100-more">
                            <Link to={'/'} className={'text-start w-100 d-block px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
                            <div className={'h-100 d-flex align-items-center justify-content-center'} style={{ gap: '80px' }}>
                                <div>
                                    <h5 className={'mb-lg-5 loginTitle'}>Hi, Welcome back</h5>
                                    <div className="trevelAgency-logo text-center"><img src={signBanner} className={'img-fluid'} alt={'icon'} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SingUp;