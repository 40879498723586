import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Form, Col, Button, FormLabel, Spinner } from "react-bootstrap";

// import logo_black from "";
import signBanner from "../../images/signBanner.svg";
import logo from "../../images/logo.png";
import toast from 'react-hot-toast';
import { API_BASE_URL, getBearerToken } from '../../functions';
import axios from 'axios';

const Forgot = () => {
  const [email, setemail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);




  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append('email', email);
    axios
      .post(`${API_BASE_URL}/forgot-password`, formData, {
        headers: {
          Authorization: `Bearer ${getBearerToken()}`,
        },
      })
      .then((response) => {
        setLoading(false)
        // console.log('response', response);
        toast.success(response.data.message)
      })
      .then((result) => {
        setLoading(false);
        // console.log('result', result);
        toast.success(result.response.data.message)
      })
      .catch((error) => {
        setLoading(false);
        // console.log('error', error);
        toast.error(error.response.data.message)
      });
  };



  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <Link to={'/'} className={'text-start w-100 d-block d-xl-none px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
            <div className="login100-form">
              <Form className="validate-form w-100" onSubmit={(e) => handleSubmit(e)}>
                <span className="login100-form-title">Forgot Password</span>
                <Col xl={12} className="mb-3 mt-5">
                  <Form.Group>
                    <div className="wrap-input100 validate-input" data-validate="Email Address">
                      <FormLabel className="0">Email Address</FormLabel>
                      <input className="input100" placeholder="demo@fasdf.com" required type="text" autoComplete="on" id="email" name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                      <span className="focus-input100"></span>
                    </div>
                  </Form.Group>
                </Col>
                <Button variant="" type="submit" id="submit" className="login100-form-btn" disabled={loading}>{!loading ? 'Forget Password' : <Spinner as='span' animation='border' size='md' role='status' aria-hidden='true' />}</Button>
                <p className="mt-4 text-center">Already have an account?<Link to={'/signin'} className="fontweight600 greentext fontsize14">Login</Link></p>
              </Form>
            </div>
            <div className="login100-more">
              <Link to={'/'} className={'text-start w-100 d-block px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
              <div className={'h-100 d-flex align-items-center justify-content-center'} style={{ gap: '80px' }}>
                <div>
                  <h5 className={'mb-lg-5 loginTitle'}>Hi, Welcome back</h5>
                  <div className="trevelAgency-logo text-center"><img src={signBanner} className={'img-fluid'} alt={'icon'} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Forgot;