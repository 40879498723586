import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Form, Col, Button, FormLabel, Spinner } from "react-bootstrap";

// import logo_black from "";
import signBanner from "../../images/signBanner.svg";
import logo from "../../images/logo.png";
import toast from 'react-hot-toast';
import { API_BASE_URL, getBearerToken } from '../../functions';
import axios from 'axios';

const PageNotFound = () => {




  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <Link to={'/'} className={'text-start w-100 d-block d-xl-none px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
            <div className="login100-form">
              <Form className="validate-form w-100" >
                <span className="login100-form-title">Sorry! Page Not Found</span>
                <Col xl={12} className="mb-3 mt-5">
                  <p className="mt-4">Have an account? <Link to={'/signin'} className="fontweight600 greentext fontsize14">Login</Link></p>
                  <Link to={'/'} className={'text-start w-100 d-block fontweight700 fontsize16 greentext'}>Home Page</Link>
                </Col>
              </Form>
            </div>
            <div className="login100-more">
              <Link to={'/'} className={'text-start w-100 d-block px-4'}><img src={logo} className={'img-fluid'} alt={'icon'} /></Link>
              <div className={'h-100 d-flex align-items-center justify-content-center'} style={{ gap: '80px' }}>
                <div>
                  <h5 className={'mb-lg-5 loginTitle'}>Sorry! Page Not Found</h5>
                  <div className="trevelAgency-logo text-center"><img src={signBanner} className={'img-fluid'} alt={'icon'} /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNotFound;