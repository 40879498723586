import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SitemapGenerator = () => {
  const location = useLocation();
  const [sitemap, setSitemap] = useState('');

  useEffect(() => {
    // Function to generate the sitemap
    const generateSitemap = () => {
      // Get all the routes in your application
      const routes = [
        '/',
        '/search/:dip/:arriv/:date',
        '/aboutus',
        '/contactus',
        '/bankaccounts',
        '/signin',
        '/forgot',
        '/signup',
        '/thank-you',
        '/dashboard',
        '/bookings',
        '/book/:id',
        '/payments',
        '/airlines',
        '/sectors',
      ];

      // Create a sitemap XML string
      let sitemap = '<?xml version="1.0" encoding="UTF-8"?>\n';
      sitemap += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';

      // Iterate over the routes and add them to the sitemap
      routes.forEach((route) => {
        sitemap += '  <url>\n';
        sitemap += `    <loc>${'https://dhilluandsahi.com'}${route}</loc>\n`; // Replace with your base URL
        sitemap += '  </url>\n';
      });

      sitemap += '</urlset>';
      setSitemap(sitemap)
      // Log the generated sitemap to the console
    };

    // Generate the sitemap when the component mounts
    generateSitemap();

    // Listen for route changes and generate the sitemap again
    const unlisten = () => {
      generateSitemap();
    };

    // Clean up the listener when the component unmounts
    return unlisten;
  }, [location]);

  // Function to handle the download button click
  const handleDownload = () => {
    // console.log("handleDownload");
    const element = document.createElement('a');
    const file = new Blob([sitemap], { type: 'application/xml' });
    element.href = URL.createObjectURL(file);
    element.download = 'sitemap.xml';
    element.click();
  };

  // Render the download link/button and nothing else (this component is for generating the sitemap only)
  return (
    <div>
      <button onClick={handleDownload}>Download Sitemap</button>
    </div>
  );
};

export default SitemapGenerator;