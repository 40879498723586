import React, { useEffect, useState } from 'react';

// import component
import BookingCardLoop from '../mysectors-compo/BookingCardLoop';
import arrowicon from '../../../images/arrowicon.svg';
import arrowlefticon from '../../../images/arrowlefticon.svg';
import verticalicon from '../../../images/verticalicon.svg';
import searchicon from '../../../images/searchicon.svg';



// import images
import logo1 from '../../../images/airbluelogo.png';
import logo2 from '../../../images/egallogo.png';
import logo3 from '../../../images/emrateslogo.png';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Dropdown, Form, InputGroup, Spinner, Table } from 'react-bootstrap';
import BookingsTRLoop from './BookingsTRLoop';
import { API_BASE_URL, getBearerToken, getLoadingState, setLoadingState } from '../../../functions';
import axios from 'axios';
import ToastMessage from '../../ToastMessage';
import { useAuth } from '../auth/AuthProvider';
import toast from 'react-hot-toast';


const PendingBooking = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState('');
  const { token } = useAuth()
  const [apiParams, setApiParams] = useState({
    id: token.agent_id,
    query: ''
  });
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key

  const [apiResponse, setApiResponse] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuesry] = useState({ target: null, value: '' })
  const [search, setSearch] = useState(null)





  const handalCancel = (e, id) => {
    setLoadingState(true, 'CANCEL_BOOKING');
    axios.get(`${API_BASE_URL}/cancelbooking/${e}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setLoadingState(false, '');
      setToastMessage({ type: 'success', message: 'Ticket cancelled successfully!' });
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    }).catch(error => {
      // Handle errors
      setToastMessage({ type: 'failde', message: error.response.data.message });
      setShowToast('show')
      setLoadingState(false, '');


    });

  };

  useEffect(() => {
    setLoading(true)
    const tid = toast.loading("Please wait...");
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/${token.role === 'admin' ? `pendingpayments?page=${currentPage}` : `unpaid-bookings?page=${currentPage}`}`, {
          headers: {
            'Authorization': `Bearer ${getBearerToken()}`
          }
        });
        if (currentPage === 1) {
          setBookings(response.data.data.data);
        } else {
          setBookings(prevBookings => [...prevBookings, ...response?.data?.data?.data]);
        }
        setApiResponse(response?.data?.data);
        // if (limit === 'all') {
        //   console.log("response.data.data", response.data.data);
        //   setBookings(prevBookings => [...prevBookings, ...response.data.data.data]);
        // } else {
        //   setBookings(response.data.data.data);
        // }
        setLoading(false);
        toast.dismiss(tid);
      } catch (error) {
        console.error(error);
        setLoading(false);
        toast.dismiss(tid);

      }
    };

    fetchBookings();
  }, [currentPage, search]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const nextPage = () => {
    if (apiResponse.next_page_url) {
      setCurrentPage(currentPage + 1);
      setLoading(true)
    }
  };

  const prevPage = () => {
    if (apiResponse.prev_page_url) {
      setCurrentPage(currentPage - 1);
      setBookings(prevBookings => prevBookings.slice(0, -apiResponse.per_page));
      setLoading(true)
    }
  };

  const handleSearch = (e) => {
    e.preventDefault()
    setSearch(searchQuery.value)
    setCurrentPage(1)
  }

  // console.log("asdfasd", searchQuery, search);
  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        <h4>Pending Bookings</h4>
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      {loading && bookings.length < 1 ? (
        // Show loading spinner or skeleton loader while data is being fetched
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
          <Card.Header className="px-3 px-lg-4 py-3 d-flex justify-content-between align-items-center" style={{ background: 'transparent', border: 'none' }}>
            <h5 className="fontsize18 blacktext fontweight600">Bookings Details</h5>
            {token.role === 'admin' ? <div className="d-flex">
              <Form onSubmit={handleSearch} className='d-flex'>
                <Form.Select
                  className={searchQuery.target ? 'me-2' : ''}
                  value={searchQuery.target || ''}
                  onChange={(e) => { setSearchQuesry({ value: '', target: e.target.value }) }}
                  required
                >
                  <option className='bluetext' value={''}>Search By</option>
                  <option value={'pnr'}>PNR</option>
                  <option value={'booking_date'}>Booking Date</option>
                  <option value={'agent_name'}>Agent Name</option>
                  {/* <option value={'departure_date'}>Departure Date</option> */}
                </Form.Select>
                {searchQuery.target &&
                  <>
                    {searchQuery.target.includes('date') ?
                      <InputGroup className="mb-0 d-md-flex d-none border rounded me-2">
                        <Form.Control className='border-0' type='date' aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => { setSearchQuesry({ ...searchQuery, value: e.target.value }) }} value={searchQuery.value} />
                        <Button variant={''} type='submit' id="basic-addon1"><img className={'img-fluid'} src={searchicon} alt={'icon'} /></Button>
                      </InputGroup>
                      :
                      <InputGroup className="mb-0 d-md-flex d-none border rounded me-2">
                        <Form.Control className='border-0' placeholder={`Search by ${searchQuery.target}`} aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setSearchQuesry({ ...searchQuery, value: e.target.value })} value={searchQuery.value} />
                        <Button variant={''} type='submit' id="basic-addon1"><img className={'img-fluid'} src={searchicon} alt={'icon'} /></Button>
                      </InputGroup>
                    }
                  </>}
              </Form>
              {search ? <Button className='redtext' variant={''} type='button' id="basic-addon1" onClick={(e) => { setSearchQuesry({ target: null, value: null }), setSearch(null), setCurrentPage(1) }}>Clear
                {/* <img className={'img-fluid'} src={searchicon} alt={'icon'} /> */}
              </Button> : null}
            </div> : null}
          </Card.Header>
          <Card.Body className="p-0 mb-3">
            <BookingsTRLoop
              data={bookings}
              toastMessage={toastMessage}
              setToastMessage={setToastMessage}
              isLoading={isLoading}
              loadingKey={loadingKey}
              showToast={showToast}
              setShowToast={setShowToast} />
            {/* <Table responsive="sm" className="mb-0 allView">
              <thead className="thead-dark">
                <tr>
                  <th>Id</th>
                  <th>PNR</th>
                  <th>Date</th>
                  <th>Sector</th>
                  <th>Airline</th>
                  <th>Pay. Status</th>
                  <th>Agent Name</th>
                  <th>Pse. Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {bookings?.map((booking) => (
                  <tr key={booking.id}>
                    <td>
                      <Link to={`/dashboard/book/${booking.id}`} className="text-decoration-none bluetext fontweight600">
                        {booking.id} - view booking
                      </Link>
                    </td>
                    <td>
                      <Link to={`/dashboard/pnr/${booking.pnr}`} className="text-decoration-none bluetext fontweight600">
                        {booking.pnr}
                      </Link>
                    </td>
                    <td>{booking.date}</td>
                    <td>{booking.sector}</td>
                    <td>
                      <Link to="#" className="d-flex align-items-center text-decoration-none">
                        <div className="AllPhoto">
                          <img className="img-fluid" src={booking.airline_logo} alt="icon" />
                        </div>
                      </Link>
                    </td>
                    <td>
                      <Badge
                        bg=""
                        className={`p-2 py-1 ${booking.payment_status === '2' ? 'successcolorbg whitetext' : booking.payment_status === '1' ? 'warninglighterbg yellowtext' : booking.payment_status === '0' && 'errorlighterbg redtext'}`}
                      >
                        {booking.payment_status === '2' ? 'Paid' : booking.payment_status === '1' ? 'partial' : booking.payment_status === '0' && 'Unpaid'}
                      </Badge>
                    </td>
                    <td>{booking.agent_name}</td>
                    <td>{booking.firstname}</td>
                    <td>
                      <Dropdown className="" align="end" autoClose="outside">
                        <Dropdown.Toggle className="" variant="" id="dropdown-autoclose-outside">
                          <img className="img-fluid" src={verticalicon} alt="icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Link to={`/dashboard/book/${booking?.id}`} className="dropdown-item">
                            View
                          </Link>
                          <Link to={'#'} className={'dropdown-item'} onClick={(e) => handalCancel(booking.id)}>
                            {isLoading && loadingKey === 'CANCEL_BOOKING' ?
                              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                              :
                              'Cancel Booking'
                            }
                          </Link>
                          <Link to={`/dashboard/pnr/${booking.pnr}`} className="dropdown-item">
                            Open full PNR
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
            {bookings.length === 0 && (
              <div className="mt-3 w-100 text-center">
                No bookings found.
              </div>
            )}
            {loading && bookings.length > 0 && (
              // Show loading spinner or skeleton loader while data is being fetched
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>)}
          </Card.Body>
          <Card.Footer className='px-3 px-lg-4 py-3 text-end' style={{ background: 'transparent', }}>
            <div className="d-flex align-items-center justify-content-end">
              <Badge bg={''} className={'p-2 py-1 gradiant2bg greentext'}>{bookings && bookings?.length} <span className='blacktext'>of</span> {apiResponse?.total}</Badge>
              <p className='p-0 m-0 fontsize12 blacktext fontweight500 mx-3'>Per Page : {apiResponse?.per_page} </p>
              <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={prevPage} disabled={!apiResponse.prev_page_url}><img className={'img-fluid'} src={arrowlefticon} alt={'icon'} /></Button>
              <span>{currentPage}</span>
              <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={nextPage} disabled={!apiResponse.next_page_url}><img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Button>
            </div>
          </Card.Footer>
        </Card>
      )}
    </>
  )
}

export default PendingBooking;