import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken, handleMouseWheel } from '../../functions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import toast from 'react-hot-toast';

const AddTicketPay = ({ show, setShow, editData }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    // const Navigate = useNavigate()
    const [editMode, setEditMode] = useState(false);

    // console.log("editData", editData);


    const [data, setData] = useState({
        booking_id: '',
        receive_date: '',
        receive_amount: '',
        text: '',
    });

    useEffect(() => {
        // If editData is provided, update the data state with the editData
        if (editData) {
            setData({ ...editData });
            setEditMode(true);
        } else {
            // If editData is not provided, reset the data state for adding a new sector
            setData({
                booking_id: '',
                receive_date: '',
                receive_amount: '',
                text: '',
            });
            setEditMode(false);
        }
    }, [editData]);

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData();

        formData.append('booking_id', data.id)
        formData.append('receive_date', data.receive_date)
        formData.append('receive_amount', data.receive_amount)
        formData.append('text', data.text)
        // console.log("formData",formData);
        if (editMode) {
            // Update an existing airline
            // Assuming editData contains the ID of the airline to be updated
            axios
                .post(`${API_BASE_URL}/update-booking-payment`, formData, {
                    headers: {
                        'Authorization': `Bearer ${getBearerToken()}`
                    }
                })
                .then((response) => {
                    toast.success(response.data.message)
                    setLoading(false);
                    handleClose();
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 2000);
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                    setLoading(false);
                });
        }
    };


    // console.log(data);
    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit}>

                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">{editMode ? 'Edit Payment' : 'Add Payment'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        <Row className={'mt-4'}>
                            <p>Details : </p>
                            <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Total Amount</Form.Label>
                                    <Form.Control type="number" readOnly name={''} id={''} placeholder={'Amount'}
                                        // onChange={(e) => setData({ ...data, total_amount: e.target.value })}
                                        // required
                                        value={data?.final_total}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Received</Form.Label>
                                    <Form.Control type="number" readOnly name={''} id={''} placeholder={'Received'}
                                        // onChange={(e) => setData({ ...data, received_amount: e.target.value })}
                                        value={data?.received_amount}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4 mb-lg-0'} xl={'4'} lg={'4'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Remaining (auto)</Form.Label>
                                    <Form.Control type="text" readOnly name={''} id={''} placeholder={'Remaining'} value={data?.remaining_amount}
                                    // onChange={(e) => setData({ ...data, remaining_amount: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={'mt-4'}>
                            <p>Update Payment : </p>
                            <Col className={'mb-4 mb-lg-0'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Received Amount</Form.Label>
                                    <Form.Control type="number" name={''} id={''} placeholder={'Amount'}
                                        onChange={(e) => setData({ ...data, receive_amount: e.target.value })}
                                        onWheel={handleMouseWheel}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4 mb-lg-0'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Receving Date</Form.Label>
                                    <Form.Control type="date" name={''} id={''} placeholder={'date'} required
                                        onChange={(e) => setData({ ...data, receive_date: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mt-4 mb-lg-0'} xl={'12'} lg={'12'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control type="textarea" rows={3} name={''} id={''} placeholder={'Description'}
                                        onChange={(e) => setData({ ...data, text: e.target.value })}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    {/*  */}
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading} type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : editMode ? 'Update' : 'Save'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default AddTicketPay;