import React, { useEffect, useState } from 'react';
import { API_BASE_URL, getBearerToken, getLoadingState, setLoadingState } from '../../../functions';
import axios from 'axios';
import ToastMessage from '../../ToastMessage';
import { Badge, Card, Col, Dropdown, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';



// import component
import arrowicon from '../../../images/arrowicon.svg';
import verticalicon from '../../../images/verticalicon.svg';
import BookingsTRLoop from '../booking-compo/BookingsTRLoop';

const Payments = () => {
  const [payments, setPayments] = useState('')
  const [show, setShow] = useState(false)
  const [showToast, setShowToast] = useState('show')
  const [toastMessage, setToastMessage] = useState(null);
  const { isLoading, loadingKey } = getLoadingState(); // Get the current loading state and key
  const [loading, setLoading] = useState(false);
  



  useEffect(() => {
    setLoadingState(true, 'PAYMENTS_LIST');
    setLoading(true)
    axios.get(`${API_BASE_URL}/listofallpendingpayments`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      setLoadingState(false, '');
      setPayments(response?.data?.data)
      setLoading(false)
    }).catch(error => {
      // Handle errors
      console.error(error);
      setLoadingState(false, '');
      setLoading(false)

    });
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])


  // console.log(payments);

  return (
    <>
      {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={showToast} setShow={setShowToast} />}
      <div className="main_heading mb-4 d-flex align-items-center">
        <h4>Payments</h4>
        {/* <Link to="#" className="ms-auto btn greenbg text-white fontsize14 fontweight700" style={{ minWidth: '160px', maxWidth: '160px' }}>
              Add New Airline
            </Link> */}
      </div>
      {loading && payments.length < 1 ? (
        // Show loading spinner or skeleton loader while data is being fetched
        <div className={'mt-3 w-100 text-center'}>
          <Spinner as='span' animation='border' size='lg' role='status' aria-hidden='true' />
        </div>
      ) : (
        <Row className={'m-0 p-0'}>
          <Col className={'mb-4 p-0'} xxl={'12'} xl={'12'} lg={'12'}>
            <Row className={'m-0 p-0'}>
              <Col className={'mb-4'} xxl={'4'} xl={'4'} lg={'4'}>
                <Card className="p-4 shadow-sm">
                  <p>Total Sale</p>
                  <h5 className='fontweight600 greentext'>{payments?.report?.total_sale || 'N/A'}</h5>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'4'} xl={'4'} lg={'4'}>
                <Card className="p-4 shadow-sm">
                  <p>Received Payment</p>
                  <h5 className='fontweight600 mainorange'>{payments?.report?.received_payment || 'N/A'}</h5>
                </Card>
              </Col>
              <Col className={'mb-4'} xxl={'4'} xl={'4'} lg={'4'}>
                <Card className="p-4 shadow-sm">
                  <p>Pending Payment</p>
                  <h5 className='fontweight600 redtext'>{payments?.report?.pending_payment || 'N/A'}</h5>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className={'mb-4 p-0'} xxl={'12'} xl={'12'} lg={'12'}>
            <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
              <Card.Header className="px-3 px-lg-4 py-3" style={{ background: 'transparent', border: 'none' }}>
                <h5 className="fontsize18 blacktext fontweight600">Fully Pending Payments</h5>
              </Card.Header>
              <Card.Body className="p-0 mb-3">
                <BookingsTRLoop
                  data={payments?.pending}
                  toastMessage={toastMessage}
                  setToastMessage={setToastMessage}
                  isLoading={isLoading}
                  loadingKey={loadingKey}
                  showToast={showToast}
                  setShowToast={setShowToast} />
                {payments?.pending?.length === 0 && (
                  <div className="mt-3 w-100 text-center">
                    No bookings found.
                  </div>
                )}
                {/* {loading && bookings.length > 0 && (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>)} */}
              </Card.Body>
              <Card.Footer className="px-3 px-lg-4 py-3 text-end" style={{ background: 'transparent' }}>
                <Link to="#" className="fontsize14 blacktext fontweight600 text-decoration-none">
                  View All <img className="img-fluid" src={arrowicon} alt="icon" />
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col className={'mb-4 p-0'} xxl={'12'} xl={'12'} lg={'12'}>
            <Card className="" style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
              <Card.Header className="px-3 px-lg-4 py-3" style={{ background: 'transparent', border: 'none' }}>
                <h5 className="fontsize18 blacktext fontweight600">Partial Pending Payments</h5>
              </Card.Header>
              <Card.Body className="p-0 mb-3">
                <BookingsTRLoop
                  data={payments?.partial}
                  toastMessage={toastMessage}
                  setToastMessage={setToastMessage}
                  isLoading={isLoading}
                  loadingKey={loadingKey}
                  showToast={showToast}
                  setShowToast={setShowToast} />
                {payments?.partial?.length === 0 && (
                  <div className="mt-3 w-100 text-center">
                    No bookings found.
                  </div>
                )}
                {/* {loading && bookings.length > 0 && (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>)} */}
              </Card.Body>
              <Card.Footer className="px-3 px-lg-4 py-3 text-end" style={{ background: 'transparent' }}>
                <Link to="#" className="fontsize14 blacktext fontweight600 text-decoration-none">
                  View All <img className="img-fluid" src={arrowicon} alt="icon" />
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export default Payments;