import React, { useRef, useEffect, useState } from 'react';

const ToastMessage = ({ message, type, show, setShow }) => {
    const toastRef = useRef(null); // Add a ref for the toast element


    useEffect(() => {
        if (typeof window.bootstrap !== 'undefined' && typeof window.bootstrap.Toast !== 'undefined') {
            // Show the toast when component mounts
            const toast = toastRef.current;
            const bsToast = new window.bootstrap.Toast(toast);
            bsToast.show();

            // Hide the toast when component unmounts
            return () => {
                bsToast.hide();
            };
        }
    }, []);

    // Define CSS classes for different types of toast messages
    const toastClasses = {
        success: 'bg-success text-light',
        error: 'bg-danger text-light'
    };
    // console.log(show);

    return (
        <>
            {show == 'show' &&
                <div
                    ref={toastRef} // Set the ref for the toast element
                    className={`toast ${toastClasses[type]} show`}
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    style={{ position: 'absolute', top: '1rem', right: '1rem', zIndex: '999' }}
                >
                    <div className="toast-header whitebg">
                        <strong className="me-auto ">{type === 'success' ? 'Success' : 'Error'}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={() => setShow('hide')}></button>
                    </div>
                    <div className="toast-body ">
                        {/* Render toast message */}
                        {message}
                    </div>
                </div>

            }
        </>
    );
};

export default ToastMessage;
