import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import phone from '../../images/phone-48.png'
import whatsapp from '../../images/whatsapp-48.png'
import trust from '../../images/trust.jpg'

const ContactUs = () => {

  const message = 'Hello, I want some Information.'; // Replace with your desired message

  // Encode the message for the URL
  const encodedMessage = encodeURIComponent(message);

  const getWhatsappUrl = (number) => {
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+92${number}&text=${encodedMessage}`;
    return whatsappUrl;
  }
  // Construct the WhatsApp URL
  return (
    <>
      <section className='herobg' style={{ paddingTop: '77px' }}>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11 text-start'}>
          <h1 className='whitetext pt-5'>Contact Us</h1>
        </Col>
      </section>
      <section>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11'}>
          <div className="row mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Faizan Afzal Travel & Tours</p>
              <h6 className="title">Proprietor Message</h6>
              <p className='pt-2 mb-3'>
                I am M. Afzal Qadri, the proud proprietor of Faizan Afzal Travel & Tours Daska. At our agency, we believe that travel is not just a journey; it's an experience that enriches the soul and broadens horizons. With a deep passion for exploration and a commitment to exceptional service, we strive to create unforgettable memories for every traveler. Our dedicated team and I are here to ensure your trips are not just hassle-free but truly extraordinary. Thank you for choosing Faizan Afzal Travel & Tours. Let's embark on remarkable journeys together.              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>For Call</p>
                  <a className="d-flex align-items-center" href='tel:+923007443286'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0300 7443286
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>For Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3007443286')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0300 7443286
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 ps-lg-5 mt-4 mt-lg-0">
              <div className="row text-center">
                <div className='about-sec2'>
                  <img src={trust} alt='' />
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4 mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>DHILLU & SAHI Travel & Tours</p>
              <h6 className="title fontsize22">All Inquiries</h6>
              <p className='pt-2 mb-3'>
                <b>Proprietor : </b>M. Ashraf Sahi, Dhillu and Sahi Travels: Your Passport to Exceptional Journeys. Experience seamless travel solutions, tailored to perfection. Your adventure begins here.
              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>For Call</p>
                  <a className="d-flex align-items-center" href='tel:+923227031527'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0322 7031527
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>For Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3227031527')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0322 7031527
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>DHILLU & SAHI  Manpower Agency</p>
              <h6 className="title fontsize22">All Inquiries</h6>
              <p className='pt-2 mb-3'>
                <b>Proprietor : </b>M. Waseem Dhillu, DHILLU & SAHI  Manpower Agency : Your Passport to Exceptional Journeys. Experience seamless travel solutions, tailored to perfection. Your adventure begins here.
              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>For Call</p>
                  <a className="d-flex align-items-center" href='tel:+923204817601'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0320 4817601
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>For Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3204817601')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0320 4817601
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4 mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Services</p>
              <h6 className="title fontsize22">Ticketing Desk</h6>
              <p className='pt-2 mb-3'>
                At our Ticketing Desk, we offer seamless booking experiences tailored to your preferences. Get ready to embark on your next adventure hassle-free, with our expert assistance and unbeatable fares.
              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>Waseem Dhillu</p>
                  <a className=" mb-2 d-flex align-items-center" href='tel:+923204817601'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0320 4817601
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3204817601')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0320 4817601
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Services</p>
              <h6 className="title fontsize22">Visit Visa, Insurance, Hotel Booking</h6>
              <p className='pt-2 mb-3'>
                Explore the world with ease through our Visit Visa Info service. Our experts provide comprehensive guidance and swift processing, ensuring you obtain your visa stress-free. Let us simplify your travel plans and make your dream destinations a reality.
              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>Sharjeel</p>
                  <a className="d-flex align-items-center" href='tel:+923334817601'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0333 4817601
                  </a>
                  <a className="d-flex align-items-center" href='tel:+92526613200'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    052 6613200
                  </a>
                  <a className="d-flex align-items-center" href='tel:+92526618500'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    052 6618500
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3204817601')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0320 4817601
                  </a>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3334817601')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0333 4817601
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-4 mb-5 howWeWork">
            <div className="col-xl-6 col-lg-6 mb-4 mb-lg-0 text-center text-lg-start">
              <p className="m-0 mb-2 mainblue" style={{ fontSize: 16, color: '#2405F2', fontWeight: 500 }}>Services</p>
              <h6 className="title fontsize22">Group Desk</h6>
              <p className='pt-2 mb-3'>
                we specialize in arranging seamless travel experiences for groups of any size. Enjoy exclusive group discounts, personalized service, and stress-free coordination, ensuring a smooth journey for everyone in your party. Let us handle the details while you focus on making unforgettable memories together.
              </p>
              <div className="d-flex">
                <div className='pe-5'>
                  <p className='mb-2 fontweight600'>Mohad Dhillu </p>
                  <a className=" mb-2 d-flex align-items-center" href='tel:+923051639255'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0305 1639255
                  </a>
                  <a className=" mb-2 d-flex align-items-center" href='tel:+923194817601'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0319 4817601
                  </a>
                  <a className=" mb-2 d-flex align-items-center" href='tel:+923004988422'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    0300 4988422
                  </a>
                  <a className=" mb-2 d-flex align-items-center" href='tel:+92526613200'>
                    <div className='icon pe-2'><img src={phone} alt={'alt'} className="img-fluid" /></div>
                    052 6613200
                  </a>
                </div>
                <div>
                  <p className='mb-2 fontweight600'>Whatsapp</p>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3051639255')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0305 1639255
                  </a>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3194817601')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0319 4817601
                  </a>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3004988422')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0300 4988422
                  </a>
                  <a className="d-flex align-items-center" href={getWhatsappUrl('3227031527')} target="_blank" rel="noopener noreferrer">
                    <div className='icon pe-2'><img src={whatsapp} alt={'alt'} className="img-fluid" /></div>
                    0322 7031527
                  </a>
                </div>
              </div>
            </div>
          </div>



          {/* <div class="section-title">
            <h2>Contac Us</h2>
            <h3> Let's talk with <span>Us</span></h3>
            <p className='mb-3'>Ready to embark on your next adventure? We're here to help!</p>
          </div>
          <Row className='my-5'>
            <Col xxl={'6'} xl={'6'} lg={'6'} className={''}>
              <div>
                <img src='' alt='' />
              </div>
            </Col>
            <Col xxl={'6'} xl={'6'} lg={'6'} className={''}>
              <Form className={'AllForm mt-2'} >
                <div className="inputsbox">
                  <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1" n>
                    <Form.Label>Name</Form.Label>
                    <Form.Control

                      required
                      type="text"
                      name={''}
                      id={''}
                      placeholder={'Name'}
                    />
                  </Form.Group>
                  <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control

                      required
                      type="email"
                      name={''}
                      id={''}
                      placeholder={'name@example.com'}

                    />
                  </Form.Group>
                  <Form.Group className="form-group mb-3" controlId="exampleForm.ControlInput1" >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className='height-unset'
                      as={'textarea'}
                      rows={3}
                      placeholder={'Write you message here ....'}
                    />
                  </Form.Group>
                </div>
                <Button variant={''} type='submit' className={'basic-btn text-uppercase'} >Submit</Button>
              </Form>
            </Col>
          </Row> */}
        </Col>
      </section>
    </>
  )
}

export default ContactUs
