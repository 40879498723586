import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Card, Form, InputGroup, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';

// images import
import GroupTRLoop from './GroupTRLoop';
import { API_BASE_URL, getBearerToken } from '../../../functions';

// images import
import arrowicon from '../../../images/arrowicon.svg';
import arrowlefticon from '../../../images/arrowlefticon.svg';
import searchicon from '../../../images/searchicon.svg';
import toast from 'react-hot-toast';




const Groups = () => {

  const [groups, setGroups] = useState('')
  const [loading, setloading] = useState(false)
  const [groupsApi, setGroupsApi] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuesry] = useState({ date: null, pnr: null })
  const [setPnr, setSetPnr] = useState(null)


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // useEffect(() => {
  //   axios.get(`${API_BASE_URL}/listofgroups`, {
  //     headers: {
  //       'Authorization': `Bearer ${getBearerToken()}`
  //     }
  //   }).then(response => {
  //     // Handle the response data

  //     setGroups(response?.data?.data?.data)
  //     setGroupsApi(response?.data?.data)
  //   }).catch(error => {
  //     // Handle errors
  //     console.error(error);
  //   });
  // }, [])
  useEffect(() => {
    setloading(true)
    const tid = toast.loading("Please wait...");
    axios.get(`${API_BASE_URL}/listofgroups?page=${currentPage}${searchQuery.date ? `&date=${searchQuery.date}` : ''}${searchQuery.pnr ? `&pnr=${searchQuery.pnr}` : ''}`, {
      headers: {
        'Authorization': `Bearer ${getBearerToken()}`
      }
    }).then(response => {
      // Handle the response data
      toast.dismiss(tid);
      if (currentPage === 1) {
        setGroups(response?.data?.data?.data);
      } else {
        setGroups(prevGroups => [...prevGroups, ...response?.data?.data?.data]);
      }
      setGroupsApi(response?.data?.data);
      setloading(false)
    }).catch(error => {
      // Handle errors
      console.error(error);
      setloading(false)
      toast.dismiss(tid);
    });
  }, [currentPage, searchQuery]);

  const nextPage = () => {
    if (groupsApi.next_page_url) {
      setCurrentPage(currentPage + 1);
      setloading(true)
    }
  };

  const prevPage = () => {
    if (groupsApi.prev_page_url) {
      setCurrentPage(currentPage - 1);
      setGroups(prevGroups => prevGroups.slice(0, -groupsApi.per_page));
      setloading(true)
    }
  };
  const handleSearch = (e) => {
    e.preventDefault()
    setSearchQuesry({ ...searchQuery, pnr: setPnr })
    setCurrentPage(1)
  }
  // console.log(searchQuery);
  return (
    <>
      <div className={'main_heading mb-4 d-flex align-items-center'}>
        <h4>Groups : {groupsApi?.total}</h4>
        <Link to={'../addnewgroup'} className='ms-auto btn greenbg text-white fontsize14 fontweight700' style={{ minWidth: '160px', maxWidth: '160px' }}>Add New Group </Link>
      </div>
      {/*  */}
      <Card className='' style={{ boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)' }}>
        <Card.Header className='px-3 px-lg-4 py-3 d-flex justify-content-between align-items-center' style={{ background: 'transparent', border: 'none', }}>
          <h5 className={'fontsize18 blacktext fontweight600'}>Groups</h5>
          <div className="d-flex">
            <Form onSubmit={handleSearch} className='me-2'>
              <InputGroup className="mb-0 d-md-flex d-none border rounded">
                <Form.Control className='border-0' placeholder="Search by PNR" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setSetPnr(e.target.value)} value={setPnr} />
                <Button variant={''} type='submit' id="basic-addon1"><img className={'img-fluid'} src={searchicon} alt={'icon'} /></Button>
              </InputGroup>
            </Form>
            <Form className='me-2'>
              <InputGroup className="mb-0 d-md-flex d-none border rounded">
                <Form.Control className='border-0' type='date' aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setSearchQuesry({ ...searchQuery, date: e.target.value })} value={searchQuery.date} />
              </InputGroup>
            </Form>
            {(searchQuery.date || searchQuery.pnr) ? <Button className='redtext' variant={''} type='button' id="basic-addon1" onClick={() => { setSearchQuesry({ date: '', pnr: null }), setSetPnr(''), setCurrentPage(1) }}>Clear
              {/* <img className={'img-fluid'} src={searchicon} alt={'icon'} /> */}
            </Button> : null}
          </div>
        </Card.Header>
        <Card.Body className={'p-0 mb-3'}>
          <Table responsive={'sm'} className={'mb-0 allView'}>
            <thead className={'thead-dark'}>
              <tr>
                <th>PNR</th>
                <th>Date</th>
                <th>Sector</th>
                <th title='Total Tickets we have in this PNR'>T.T We Have</th>
                <th title='Total Tickets we allow to sell'>T.T Selling</th>
                <th>Price/Ticket</th>
                <th>Infant Price</th>
                <th>Sold</th>
                <th>Add By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {groups && <GroupTRLoop groups={groups} />}
              {/* {groups && groups?.map((val) => {
                return (
                  <GroupTRLoop groups={groups}   />
                )
              })} */}
            </tbody>
          </Table>
          {!groups || loading ? (
            <div className={'mt-3 w-100 text-center'}>
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            </div>
          ) : null}
          {groups && groups?.length < 1 && <div className={'mt-3 w-100 text-center'}><p>No Group Found</p></div>}
        </Card.Body>
        <Card.Footer className='px-3 px-lg-4 py-3 text-end' style={{ background: 'transparent', }}>
          <div className="d-flex align-items-center justify-content-end">
            <Badge bg={''} className={'p-2 py-1 gradiant2bg greentext'}>{groups && groups?.length} <span className='blacktext'>of</span> {groupsApi?.total}</Badge>
            <p className='p-0 m-0 fontsize12 blacktext fontweight500 mx-3'>Per Page : {groupsApi?.per_page} </p>
            <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={prevPage} disabled={!groupsApi.prev_page_url}><img className={'img-fluid'} src={arrowlefticon} alt={'icon'} /></Button>
            <span>{currentPage}</span>
            <Button to={'#'} className={'fontsize14 footerbtn blacktext fontweight600 text-decoration-none  whitebg border-0'} onClick={nextPage} disabled={!groupsApi.next_page_url}><img className={'img-fluid'} src={arrowicon} alt={'icon'} /></Button>
          </div>
        </Card.Footer>
      </Card>
    </>
  )
}

export default Groups;