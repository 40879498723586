import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { API_BASE_URL, getBearerToken } from '../../../functions';
import axios from 'axios';

export const SearchData = () => {
    const { query } = useParams()
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)



    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setLoading(true)
        axios.post(`${API_BASE_URL}/searchagentbyemail`, {
            searchquery: query
        }, {
            headers: {
                'Authorization': `Bearer ${getBearerToken()}`
            }
        })
            .then(response => {
                // Handle the response data
                setData(response?.data.data)
                setLoading(false)
            }).catch(error => {
                // Handle errors
                console.error(error?.response?.data.message);
                setLoading(false)
            });
    }, [])

    // console.log(data);
    return (
        <>
            <div className={'main_heading mb-4 d-flex align-items-center'}>
                <h4>Search result for : <span className='redtext'>{query}</span></h4>
            </div>
            <div>
                {data.length > 0 ?
                    (data?.map((val, i) => {
                        return (
                            <div key={i} className='p-3 d-flex'>
                                <p className='me-2'>{val.id} - </p>
                                <p className='me-3'>{val.name}</p>
                                <p className='me-3'>{val.email}</p>
                            </div>
                        )
                    })) :
                    (
                        <p>No data Found</p>
                    )
                }
            </div>
        </>
    )
}
