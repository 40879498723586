import React from 'react';


// import component
import { Outlet, useLocation } from 'react-router';
import Header from './components/PublicPages/componnents/Header';
import Footer from './components/PublicPages/componnents/Footer';

const PublicLayout = () => {
  // const location = useLocation();
  // const isActive = (url) => {
  //   return location.pathname === url;
  // }
  return (
    <>
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default PublicLayout;