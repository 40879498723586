import { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // console.log('AuthProvider');
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')) || null);
    const [token1, setToken1] = useState(JSON.parse(localStorage.getItem('token')) || null);

    const updateToken = (newToken) => {
        localStorage.setItem('token', JSON.stringify(newToken));
        setToken(newToken);
    };

    const updateTokenVAlue = (newTokenindex, value) => {
        setToken1({ ...token1, change_password: value })
        // setToken(token1);
    };

    useEffect(() => {
        // console.log('after ===>', token1);
        token1 && localStorage.setItem('token', JSON.stringify(token1));
    }, [token1])

    return (
        <AuthContext.Provider value={{ token, updateToken, updateTokenVAlue }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    // console.log('useAuth');
    return useContext(AuthContext);
};
