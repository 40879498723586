import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken, handleMouseWheel } from '../../functions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import toast from 'react-hot-toast';

const ConfirmBooking = ({ show, setShow, path = '', ConData }) => {
    const handleClose = () => setShow(false);
    const [toastMessage, setToastMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    // const Navigate = useNavigate();

    const [data, setData] = useState({
        discount: '',
        receive_payment: '',
        sure: null
    });


    // console.log(ConData);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();

        formData.append('booking_id', ConData.id);
        formData.append('discount', data.discount);
        formData.append('receive_payment', data.receive_payment);
        formData.append('sure', data.sure);

        axios
            .post(`${API_BASE_URL}/confirmbooking`, formData, {
                headers: {
                    Authorization: `Bearer ${getBearerToken()}`,
                },
            })
            .then((response) => {
                toast.success(response.data.message)
                setLoading(false);
                handleClose();
            })
            .then((result) => {
                setLoading(false);
                toast.success(result.response.data.message)
            })
            .catch((error) => {
                toast.error(error.response.data.message)
                setLoading(false);
            });
    };

    const handleCheckboxChange = (e) => {
        // If the checkbox is checked, set the value to 'Agent', otherwise set it to an empty string
        const newValue = e.target.checked ? true : null;
        setData({ ...data, sure: newValue })
    }

    // console.log("confirm => ", data);
    return (
        <>
            {toastMessage && <ToastMessage type={toastMessage.type} message={toastMessage.message} show={toastMessage.show} />}
            <Modal show={show} size="lg" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'} onSubmit={handleSubmit}>

                    <Modal.Header closeButton className="d-flex px-4 align-items-center">
                        <Modal.Title id="contained-modal-title-vcenter">Confirm Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        {/*  */}
                        <Row className={'my-4'}>
                            {ConData.cancel_status == 1 &&
                                <Col className={'mb-4'} xl={'12'}>
                                    <p>You are confirming cancelled booking! Are you sure you want to confirm it ?</p>
                                    <Form.Check
                                        type={'checkbox'}
                                        label={'Yes Sure!'}
                                        className={'me-4'}
                                        id={'radioAgent'} // Check if the customer value is 'Agent' to set the checkbox as checked
                                        onChange={handleCheckboxChange}
                                    />
                                </Col>}
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Total Payment</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="number"
                                        value={ConData?.final_total} // Set the value prop to the corresponding data property
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Already Received Payment</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="number"
                                        value={ConData.received_amount}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Receive Payment</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        name={''}
                                        id={''}
                                        placeholder={'Receive Payment'}
                                        value={data.name} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, receive_payment: e.target.value })}
                                        onWheel={handleMouseWheel}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className={'mb-4'} xl={'6'} lg={'6'}>
                                <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Discount</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        name={''}
                                        id={''}
                                        placeholder={'Discount'}
                                        value={data.phone} // Set the value prop to the corresponding data property
                                        onChange={(e) => setData({ ...data, discount: e.target.value })}
                                        onWheel={handleMouseWheel}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                    </Modal.Body>
                    {/*  */}
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px F1F1F1bg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" disabled={loading || (ConData.cancel_status == 1 ? !data.sure : false) } type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg">
                            {loading ?
                                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                                : 'Confirm'
                            }
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ConfirmBooking;