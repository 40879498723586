import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";


// import componets
import LeftSidebar from './components/LeftSidebar';
import Header from './components/Header';
import { Col, Row } from 'react-bootstrap';
import ChangePassword from './components/Modals/ChangePassword';
import { useAuth } from './components/pages/auth/AuthProvider';
import Alert from './components/Modals/Alert';


function App() {
  const { token } = useAuth();
  const [show, setShow] = useState(false)
  const [showW, setShowW] = useState(false)

  useEffect(() => {
    // console.log("in layout =====>", token.change_password);
    if (token.change_password) {
      setShow(true)
    }
    if (token.w_verified) {
      setShowW(true)
    }
  }, [token]);

  return (
    <>
      <div className="wrapper">
        <Header />
        <LeftSidebar />
        <main className="content_page">
          <Row className={''} style={{ padding: '88px 16px' }}>
            <Col className={'m-0 mx-auto'} xxl={'10'} xl={'10'} lg={'10'}>
              <Outlet />
            </Col>
          </Row>
        </main>
      </div>
      {show && <ChangePassword path='auto' show={show} setShow={setShow} />}
      {showW && <Alert path='auto' show={showW} setShow={setShowW} />}
    </>
  );
}

export default App;
