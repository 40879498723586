import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API_BASE_URL, getBearerToken, handleMouseWheel } from '../../functions';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
import ToastMessage from '../ToastMessage';
import toast from 'react-hot-toast';

const ConfirmationPopup = ({ show, setShow, handleConfirm }) => {
    const handleClose = () => setShow(false);


    return (
        <>
            <Modal show={show} size="md" onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
                <Form className={'AllForm'}>

                    <Modal.Header closeButton className="d-flex px-4 align-items-center border">
                        <Modal.Title id="contained-modal-title-vcenter" className='redtext'>Confirmation!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        <p className='fontweight500 fontsize16'>This action can not be undo. Are you sure to proceed this?</p>

                    </Modal.Body>
                    {/*  */}
                    <Modal.Footer className="">
                        <Button variant="" className="w-100px lightredbg fontsize14 fontweight700 me-3" onClick={handleClose}>Cancel</Button>
                        <Button variant="" type='submit' className="w-100px text-white fontweight700 fontsize14 greenbg" onClick={handleConfirm}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}

export default ConfirmationPopup;